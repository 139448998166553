
<template>
<v-form 
    ref="form"
    v-model="valid"
    lazy-validation
>

  <div v-if="lodaded">

            <TextField :item="{ value: 'name', name: 'nom de la tabulation',defaultprops:{ show: true }}"
            :keyup="change"
            :valField="thisTab.name" />

            <TextField :item="{ value: 'order', name: 'Ordre',format:'number',defaultprops:{ show: true }}"
             :keyup="change"
            :valField="thisTab.order" />

            <TextField :item="{ value: 'class', name: 'class',defaultprops:{ show: true }}"
            :keyup="changeParam"
            :valField="thisTab.params.class" />

            <TextField :item="{ value: 'icon', name: 'Icône',defaultprops:{ show: true }}"
            :keyup="changeParam"
            :valField="thisTab.params.icon" />

            <SwitchC
            
            :item="{ name: 'dark mode', value: 'dark',defaultprops:{ show: true }}"
            :valField="thisTab.params.dark"
            :change="changeParam"
            />
  </div>
  <div class="text-center">
    <v-btn class="primary" @click="validate" :disabled="!valid">Enregistrer</v-btn>
  </div>
  </v-form>
</template>



<script>


export default {

    props : ['page','modal'],

    components: {
        TextField: () => import('../../fields/textfield.vue'),
        SwitchC: () => import('../../fields/switch.vue'),
    },

    data: function () {
        return {
          thisTab : [],
          valid : false,
          lodaded : false
        }
    },


  methods : {


        validate() {
            if(this.$refs.form.validate()) {

                this.$store.dispatch('getoverlay',true)
                this.API({type:'put', url:this.urlSend, params:this.thisTab}).then((resp) => {

                    this.$store.dispatch('getoverlay',false)
                    if(resp.data.success) {
                        this.$store.dispatch('closeModal',{page:this.$store.getters.modalShowParams({type:'to',val:this.page}).from})
                    }

                    }) 
            }

        },

        getTabFromAPI() {
          this.API({type:'get', url:this.urlSend}).then((resp) => {  
                
                this.thisTab = resp.data
                this.lodaded = true
                }
          
            )

        },

        change(item,value) {
          this.thisTab[item.value] = value
          //this.$store.dispatch('pushForm', { page:this.page, value:value, champ:item.value })
        },
        
        changeParam(item,value) {
          this.thisTab.params[item.value] = value
          //this.$store.dispatch('pushForm', { page:this.page, value:value, champ:item.value })
        }
  },


  mounted() {
    this.urlSend = (!this.modal) ? this.$route.path : this.$store.getters.modalShowParams({type:'to',val:this.page}).url
    this.getTabFromAPI()
  }

}




</script>

