<template>
    <div>
            <v-switch
                :color="prop.color"
                :label="item.name"
                :false-value="getFalse"
                :true-value="getTrue"
                v-model="model"
                @change="change(item,model)"

                :rules="prop.modelRules"
                v-if="prop.show"
                :outlined="prop.outlined"
                :filled="prop.filled"
                :solo="prop.solo"
                :hint="prop.description"
                :persistent-hint="prop.persistenthint"
                :disabled="prop.disabled"
                :class="prop.class"
                :clearable="prop.clearable"
                
            :prepend-icon="(prop.iconlr=='prepend' && prop.iconinout=='out') ? prop.iconmdi : ''"
            :prepend-inner-icon="(prop.iconlr=='prepend' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-icon="(prop.iconlr=='append' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-outer-icon="(prop.iconlr=='append' && prop.iconinout=='out') ? prop.iconmdi : ''" 

            ></v-switch>     
    
    </div>
    </template>
    
    
    
    <script>
    import mixinFields from '../../mixins/Fields'
    import mixinConditionsFields from '../../mixins/conditionsFields'
        export default {
            name : 'SwitchField',

            mixins : [mixinFields,mixinConditionsFields],

            data: function() {
                return { 
                    getFalse : false,
                    getTrue : true,
                }
            },
    
            watch : { 
    
               valField(val) {
                   this.model = val
                   this.changeStoreOnField({item:this.item,model:this.model,page:this.page}) 
               }
    
            },
    
            methods : {
    
            },

            mounted() {
                switch(this.model) {
                    case false : case true : 
                    this.getFalse = false
                    this.getTrue = true
                    break;
                    case 0 : case 1 :
                    this.getFalse = 0
                    this.getTrue = 1
                    break;
                }

            }
    
    
        }
    
    </script>
    
    