var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-row',[_c('v-col',{staticClass:"ma-1"},[_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Paramètres généraux")]),_c('v-tab',[_vm._v(" Filtre ")]),_c('v-tab',[_vm._v(" Champs affichés ")]),_c('v-tab',[_vm._v(" Modules de recherche ")]),_c('v-tab',[_vm._v(" Actions ")]),_c('v-tabs-items',{staticClass:"pl-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('span',{staticClass:"text-h6"},[_vm._v("Bouton d'ajout")]),_c('SwitchC',{attrs:{"item":{
              name: 'Affichage du bouton', value: 'display', liste: [{ text: 'OUI', value: true }, { text: 'NON', value: false }],
              defaultprops: { show: true, column: false }
            },"change":_vm.ChangeAddButton,"valField":_vm.displays.params.ajout.display}}),(_vm.displays.params.ajout.display)?_c('div',[_c('v-row',[_c('v-col',[_c('TextField',{attrs:{"item":{ name: 'Nom du bouton', value: 'text', defaultprops: { show: true } },"valField":_vm.displays.params.ajout.text,"keyup":_vm.ChangeAddButton}}),_c('TextField',{attrs:{"item":{ name: 'icône', value: 'icon', defaultprops: { show: true } },"valField":_vm.displays.params.ajout.icon,"keyup":_vm.ChangeAddButton}}),_c('Select',{attrs:{"item":{
                    name: 'type d\'affichage', value: 'type', liste: [{ text: 'en modal', value: 'modal' }, { text: 'en centre', value: 'center' }],
                    defaultprops: { show: true, column: false }
                  },"change":_vm.ChangeAddButton,"valField":_vm.displays.params.ajout.type}})],1),(_vm.displays.params.ajout.type == 'modal')?_c('v-col',{staticClass:"grey lighten-4"},[_c('span',{staticClass:"text-h6"},[_vm._v("Paramètre modal")]),(_vm.displays.params.ajout)?_c('paramDialog',{attrs:{"page":_vm.page,"params":_vm.displays.params.ajout.paramdialog},on:{"returnParamDialog":_vm.ChangeParamDialog}}):_vm._e()],1):_vm._e(),_c('v-col',[_c('ArrayTable',{attrs:{"item":{otherparams:{arrayTable:[{type:'select',key:'key',name:'Clé de la table',ArrayValue:_vm.FieldsOfModuleTable},{type:'select',key:'type',name:'type de donnée',ArrayValue:[{text:'nombre',value:'number'},{text:'texte',value:'text'},{text:'booleen',value:'bool'}]},{type:'',key:'value',name:'Valeur',description:'(Vous pouvez insérer des variables d\'environnement)'}]},
                  defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'Ajouter une variable'}},"valField":_vm.displays.params.ajout.variables,"change":(item,model) => { _vm.displays.params.ajout.variables = model; }}})],1)],1)],1):_vm._e(),_c('SwitchC',{attrs:{"item":{
              name: 'Afficher la checkbox par ligne', value: 'showselect',
              defaultprops: { show: true, column: false }
            },"change":_vm.ChangeParam,"valField":_vm.displays.params.paramtab.showselect}}),_c('SwitchC',{attrs:{"item":{
              name: 'Checkbox en entete', value: 'singleselect',
              defaultprops: { show: true, column: false }
            },"change":_vm.ChangeParam,"valField":_vm.displays.params.paramtab.singleselect}}),_c('SwitchC',{attrs:{"item":{
              name: 'Masquer la pagination', value: 'hidepagination',
              defaultprops: { show: true, column: false }
            },"change":_vm.ChangeParam,"valField":_vm.displays.params.paramtab.hidepagination}}),_c('SwitchC',{attrs:{"item":{
              name: 'Afficher le tableau uniquement si un filtre est appliqué', value: 'set',
              defaultprops: { show: true, column: false }
            },"change":(item,value) => { _vm.displays.params.displayiffilter.set = value },"valField":_vm.displays.params.displayiffilter.set}}),(_vm.displays.params.displayiffilter.set)?_c('TextField',{attrs:{"item":{ name: 'Texte à afficher si attente d\'un filtre', value: 'text', defaultprops: { show: true } },"valField":_vm.displays.params.displayiffilter.text,"keyup":(item,value) => {_vm.displays.params.displayiffilter.text = value }}}):_vm._e(),_c('Autocomplete',{attrs:{"item":{
              name: 'Ligne par page',
              value: 'itemsPerPage',
              defaultprops: { show: !_vm.displays.params.paramtab.hidepagination },
              liste: [
                { text: '5', value: 5 },
                { text: '10', value: 10 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
              ],
            },"valField":_vm.displays.params.paramtab.itemsPerPage,"change":_vm.ChangeParam}})],1),_c('v-tab-item',[_c('Filtre',{attrs:{"filtre":_vm.displays.filter,"table":_vm.module.table},on:{"recepVal":(array) => { _vm.changeEC(array, 'filter') }}})],1),_c('v-tab-item',[_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},[_c('v-tab',[_vm._v(" Pour la table")]),_c('v-tab',[_vm._v(" Pour le fichier excel ")]),_c('v-tabs-items',{staticClass:"pl-4",model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},[_c('v-tab-item',[_c('p',[_vm._v("Vous pouvez attribuer une couleur de fond pour une ligne avec la valeur as : 'cellBackColor'")]),_c('p',[_vm._v("Vous pouvez attribuer une couleur de texte pour une colonne avec valeur as : 'rowColor'")]),_c('p',[_vm._v("Vous pouvez attribuer une couleur de fond pour une colonne avec valeur as : 'rowBackColor'")]),_c('Fields',{attrs:{"table":_vm.module.table,"champs":_vm.displays.params.champs,"filter":_vm.displays.filter,"page":_vm.page},on:{"recepVal":(array) => { _vm.changeEC(array, 'champs') }}})],1),_c('v-tab-item',[_c('Fields',{attrs:{"table":_vm.module.table,"champs":_vm.displays.params.champsexcel,"filter":_vm.displays.filter,"page":_vm.page},on:{"recepVal":(array) => { _vm.changeEC(array, 'champsexcel') }}})],1)],1)],1)],1),_c('v-tab-item',[_c('div',{staticClass:"text-h6"},[_vm._v("Si le ou les modules sélectionnés se trouvent sur la même page, ils interagieront avec le tableau")]),_c('SimpleTable',{attrs:{"Headers":[
              { text: 'ID', value: 'id' },
              { text: 'champs', value: 'champs' },
            ],"datas":_vm.dataTableSearch,"selected":_vm.displays.params.searchmodule,"keyRow":"id"},on:{"getselected":_vm.getselected}}),_c('div',{staticClass:"text-h6"},[_vm._v("champs selectionnés pour la recherche globale")]),_c('Autocomplete',{attrs:{"item":{
              name: 'Champs ciblés pour recherche globale',
              value: 'globalsearch',
              defaultprops: { show: true, multiple: true },
              liste: _vm.listFields,
            },"valField":_vm.displays.params.globalsearch,"change":_vm.changeGlobalSearch}})],1),_c('v-tab-item',[_c('listActions',{key:_vm.reload,attrs:{"page":_vm.page,"module":_vm.module}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }