import api from '../services/api'
const getUser = {
    state: {

        user: { nickname: '', companie: '', iduser: '', email: '', imgSrc: '', preferences: {} },
        profils: [],

        isAuth: false,
        systemLoad: false,
        defaultPage: {},
        textConnexion: ''
    },

    getters: {

        currentUser: (state) => {
            return state.user
        },

        listProfils: (state) => {
            return state.profils
        },

        isAuth: (state) => {
            return state.isAuth
        },

        systemLoad: (state) => {
            return state.systemLoad
        },


    },

    mutations: {

        logOut: function() {
            localStorage.removeItem("tokenMinu");
            location.reload();
        },
        updateUser: function(state, elems) {

            var ee = {}
            var list = state.user
            if (elems.keys.length == 2) {
                ee[elems.keys[0]] = {}

                ee[elems.keys[0]][elems.keys[1]] = (elems.value == null) ? '' : elems.value;
                state.user[elems.keys[0]] = {...list[elems.keys[0]], ...ee[elems.keys[0]] };
            } else {
                ee[elems.keys[0]] = (elems.value == null) ? '' : elems.value;
                state.user = {...list, ...ee };
            }



        },

    },

    actions: {

        setUser: function({ state }) {
            return new Promise((resolve, reject) => {
                api.get('auth/me', { params: { url: window.location.href } })
                    .then((resp) => {

                        state.user = resp.data.user
                        state.profils = resp.data.profils
                        state.isAuth = true


                        resolve(resp.data)
                    }, error => {
                        if (error.response.data.message == 'noconnect') {
                            localStorage.removeItem("tokenMinu");
                            reject(error.response.data.message)
                        }
                    })
            })
        },
        isAuthenticated: function({ commit }, val) {
            commit('Authenticate', val)
        },


        logOut: function( { dispatch} ) {
            dispatch('textOverlay', 'déconnexion en cours...');
            dispatch('getoverlay', true)


            api.post('auth/logout').then(() => {
                location.reload()
            })


        },

        updateUser: function({ commit }, val) {
            commit('updateUser', val)
        },

        SystemLoaded: function({ state }) {
            state.systemLoad = true;
        },
    }

}
export default getUser;