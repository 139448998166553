<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres d'historique email </v-toolbar-title>
      </v-toolbar>

      <div>
        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> requete </v-tab>
          <v-tab> champs </v-tab>
          <v-tab> traduction </v-tab>
          <v-tab> tableau </v-tab>
          <v-tabs-items v-model="tab" class="pl-4">

            <v-tab-item>
              <p>Vous pouvez récupérer les historiques d'email en fonction d'un id de la table {{ module.table }} 
                (Il pourra y avoir plusieurs emails en cas de modification dans la base donnée), ou directement en récupérant l'email.</p>
              <p>Créez le filtre qui permettra de récupérer soit l'email, soit l'id spcifique de la table</p>
              <Filtre
                :filtre="displays.filter"
                :table="module.table"
                @recepVal="(data) => {displays.filter = data; $emit('recepVal',displays);}"
              />
            </v-tab-item>

            <v-tab-item>
              <p>selectionnez les emails ou vous souhaitez afficher le status</p>
              <Fields
              :table="module.table"
              :champs="displays.params.champs"
              :filter="displays.filter"
              :page="page"
              @recepVal="(data) => {displays.params.champs = data; $emit('recepVal',displays);}"
              />
            </v-tab-item>

          <v-tab-item>

          </v-tab-item>

          <v-tab-item>

            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
    </div>
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "HistoriqueEmail",
    props: ["module", "page","itemTableListe"],
  
    components: {
      Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      Fields: () => import("../outils/constructField/Field.vue"),
      /*
      Autocomplete: () => import("../../fields/autocomplete.vue"),
      Select: () => import("../../fields/select.vue"),
      ArrayTable: () => import("../../fields/arrayTable.vue"),
      texteditor : () => import("../../fields/texteditor.vue"),
      TextField: () => import("../../fields/textfield.vue"), */

    },
  
    data: function () {
      return {

        displays: this.module.displays,
        tab : null,

      };
    },
  
    watch: {

  },
  
    methods: {


  
    },
  
    mounted() {
    
    },
  };
  </script>
  
  