const constructTemplate = {

    state: {

        pageTemplate: [],
        selectedTemplate: 'default',
        showOrNot: []

    },

    getters: {

        templatePage: (state) => (id) => {
            return state.pageTemplate.find(specific => specific.page === id)
        },


        actifTemplate: (state) => (id) => {
            var e = state.pageTemplate.find(specific => specific.page === id)
            return e.templates.find(el => el.key === state.selectedTemplate);
        },
    },

    mutations: {

        init_template: function(state, liste) {
            state.selectedTemplate = 'default'
            state.pageTemplate = state.pageTemplate.filter(x => {
                return x.page != liste.page;
            })

            var temp = { page: liste.page, templates: liste.templates, selected: state.selectedTemplate, mod: false, variant: liste.variant, showornot: [] }

            var currentTemplate = liste.templates.find(el => el.key === state.selectedTemplate).template
            temp['template'] = currentTemplate
            state.pageTemplate.push(temp)
            this.commit('constructTemplate/checkConditionTemplate', { page: liste.page })
            this.commit('constructTemplate/checkConditionColsAndRows', { page: liste.page })
        },

        checkConditionTemplate: function(state, elems) {

            const pageTemplate = state.pageTemplate.find(specific => specific.page === elems.page)
            const that = this
            var variable = ''
                //on va chercher si une condition est rempli pour voir si un template est ciblé, sinon c'est défaut :
            pageTemplate.templates.forEach(function(t) {
                if (t.actionsConditions.length > 0) {
                    t.actionsConditions.forEach(function(cond) {
                        if (cond.typevar != 'none') {
                            variable = "[[" + cond.typevar + "||" + cond.varvalue + "]]";
                            variable = that._vm.replaceBy({ text: variable, page: elems.page })
                        } else {
                            variable = cond.varvalue
                        }
                        if (cond.typevar == 'form' && elems.field) {

                            if (cond.varvalue == elems.field) {

                                if (variable == cond.egal) {
                                    state.selectedTemplate = t.key
                                } else {
                                    if (cond.else && cond.else != '') state.selectedTemplate = cond.else
                                }

                            }
                        } else {

                            if (variable == cond.egal) state.selectedTemplate = t.key
                        }
                    })
                }
            })

            pageTemplate.template = pageTemplate.templates.find(el => el.key === state.selectedTemplate).template
        },

        checkConditionColsAndRows: function(state, elems) {
            // par defaut elems.array n'est pas créé, après la première boucle, il est créé
            var template = state.pageTemplate.find(specific => specific.page === elems.page)
            var loopArray = []
            var notShow = {}
            var variable = ''
            var showornot = template.showornot
            if (elems.array) {
                loopArray = elems.array
            } else {

                loopArray = template.templates.find(el => el.key === state.selectedTemplate).template;
            }

            loopArray.forEach((t, index) => {

                if (t.type == 'col' || t.type == 'row') {

                    var parent = (elems.parent == undefined) ? index : elems.parent + "-" + index;

                    if (t.actionsConditions.length > 0) {

                        notShow[parent] = false;
                        template.showornot = {...showornot, ...notShow }
                    }
                    t.actionsConditions.forEach((cond) => {

                        variable = this._vm.replaceBy({ text: cond.varvalue, page: elems.page })

                        var egal = (!isNaN(cond.egal)) ? Number(cond.egal) : cond.egal;

                        if (variable == egal) {
                            notShow[parent] = true;
                            template.showornot = {...showornot, ...notShow }
                        }


                    })

                    if (t.content) this.commit('constructTemplate/checkConditionColsAndRows', { page: elems.page, field: elems.field, array: t.content, parent: parent })
                }

            })
        },

        changeTypeTemplate: function(state, elems) {

            var temp = state.pageTemplate.find(specific => specific.page === elems.id)

            state.pageTemplate.find(specific => specific.page === elems.id).templates = elems.templates
            var currentTemplate = temp.templates.find(el => el.key === elems.key).template
            state.pageTemplate.find(specific => specific.page === elems.id).template = currentTemplate
            state.pageTemplate.find(specific => specific.page === elems.id).selected = elems.key
            state.selectedTemplate = elems.key
            state.pageTemplate.find(specific => specific.page === elems.id).variant = elems.variant
            this.commit('constructTemplate/checkConditionColsAndRows', { page: elems.id })
        },

        updateTemplate: function(state, elems) {
            state.pageTemplate.find(specific => specific.page === elems.id).templates = elems.templates

        },

        addCol: function(state, params) {
            var newCol = { type: "col", class: "", content: [], actionsConditions: [] }
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');

            deep.forEach(function(v) {
                template = template[v]['content']
            })
            template.push(newCol)
        },

        addRow: function(state, params) {
            var newRow = { type: "row", class: "", content: [], actionsConditions: [] }
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            switch (params.position) {
                case 'top':
                    if (params.place == '') {
                        template.unshift(newRow)
                    } else {
                        params.place.split('-').forEach(function(v) {
                            template = template[v]['content']
                        })
                        template.unshift(newRow)
                    }

                    break;
                case 'bottom':
                    if (params.place == '') {
                        template.push(newRow)
                    } else {
                        params.place.split('-').forEach(function(v) {
                            template = template[v]['content']
                        })
                        template.push(newRow)
                    }
                    break;
            }

        },

        changeElem: function(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');

            deep.forEach(function(v) {
                template = template[v]['content']
            })

            template = params.content

        },

        loadTemplate: function(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');

            if (params.place === '') {
                template.push(params.content)
            } else {
                deep.forEach(function(v) {
                    template = template[v]['content']
                })

                template.push(params.content)
            }


        },

        updateParam: function(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');
            const length = deep.length
            deep.forEach(function(v, i) {

                if (i == length - 1) {
                    if (params.key) {
                        template = template[v][params.item][params.key] = params.value
                    } else {
                        template = template[v][params.item] = params.value
                    }

                } else {

                    template = template[v]['content']
                }


            })
        },

        removeCol: function(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']
            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');
            var length = deep.length

            deep.forEach(function(v, i) {
                if (i == length - 1) {

                    template.splice(v, 1)

                } else {
                    template = template[v]['content']
                }


            })

        },

        applyMod(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)
            template['mod'] = params.mod
        }
    },

    actions: {

        init_template: function({ commit }, liste) {
            commit('init_template', liste)
        },

        checkConditionTemplate: function({ commit }, liste) {
            commit('checkConditionTemplate', liste)
        },

        checkConditionColsAndRows: function({ commit }, liste) {
            commit('checkConditionColsAndRows', liste)
        },

        addCol: function({ commit }, params) {
            commit('addCol', params)
        },

        addRow: function({ commit }, params) {
            commit('addRow', params)
        },

        removeCol: function({ commit }, params) {
            commit('removeCol', params)
        },

        changeElem: function({ commit }, params) {
            commit('changeElem', params)
        },

        loadTemplate: function({ commit }, params) {
            commit('loadTemplate', params)
        },
        applyMod: function({ commit }, params) {
            commit('applyMod', params)
        },
        updateParam: function({ commit }, params) {
            commit('updateParam', params)
        },
        changeTypeTemplate: function({ commit }, params) {
            commit('changeTypeTemplate', params)
        },
        updateTemplate: function({ commit }, params) {
            commit('updateTemplate', params)
        },
    },
    namespaced: true,
}
export default constructTemplate;