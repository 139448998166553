<template>
<div>

<v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="auto"
        v-if="prop.show"
      >
        <template v-slot:activator="{ on, attrs }">
          {{item.name}}
          <v-text-field
            v-model="translateResult"
            @click:append="menu = true"
            v-bind="attrs"
            readonly
            v-on="on"
            dense
            :rules="prop.modelRules"
            :outlined="prop.outlined"
            :filled="prop.filled"
            :solo="prop.solo"
            :hint="prop.description"
            :persistent-hint="prop.persistenthint"
            :disabled="prop.disabled"
            :class="prop.class"
            :clearable="prop.clearable"
            :color="prop.coloronselect"
            @click:clear="model = '-'"

            :prepend-icon="(prop.iconlr=='prepend' && prop.iconinout=='out') ? prop.iconmdi : ''"
            :prepend-inner-icon="(prop.iconlr=='prepend' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-icon="(prop.iconlr=='append' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-outer-icon="(prop.iconlr=='append' && prop.iconinout=='out') ? prop.iconmdi : ''" 

          ></v-text-field>
        </template>
        <v-date-picker
          v-model="model"
          locale="fr"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Annuler
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(model)"
          >
            OK
          </v-btn>
        </v-date-picker>

      </v-menu>

</div>
</template>



<script>

import mixinFields from '../../mixins/Fields'
import mixinConditionsFields from '../../mixins/conditionsFields'
    export default {
        name : 'datePicker',

        mixins : [mixinFields,mixinConditionsFields],

        data: function() {
            return { 
                translateResult : '',
                menu : false
            }
        },

        computed: {

        },

        watch : { 

           model(val) {
            let checkIfDate = new Date(val)    
            let value = val;     

              if (isNaN(checkIfDate)) {
                value = null
                this.translateResult = 'Choisir une date';
                this.model=''
                this.change(this.item,value);
                this.changeStoreOnField({item:this.item,model:value,page:this.page})
              } else {
                this.change(this.item,value)
                this.changeStoreOnField({item:this.item,model:value,page:this.page}) 
                this.translateResult = this.moment(value).format('DD-MM-YYYY')
                this.menu = false;
              }




           },

           valField(val) {
            this.model = val
           }

        },

        methods : {

        },

    }

</script>
