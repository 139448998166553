<template>


    <div>
        <v-file-input
        :label="item.name"
        v-model="chosenFile"
        accept=".txt,.xlsx,.csv"
        :rules="prop.modelRules"
        v-if="prop.show"
        :outlined="prop.outlined"
        :filled="prop.filled"
        :solo="prop.solo"
        :hint="prop.description"
        :persistent-hint="prop.persistenthint"
        :disabled="prop.disabled"
        :class="prop.class"
        :clearable="true"
        :color="prop.coloronselect"
        @click:clear="keyup(item,'')" 
        
        ></v-file-input>    
    </div>
    
    </template>
    
    
    
    <script>
    import mixinFields from '../../mixins/Fields'
    import mixinConditionsFields from '../../mixins/conditionsFields'
        export default {
            name : 'fileInput',
    
            mixins : [mixinFields,mixinConditionsFields],
    
            data: function() {
                return { 
                    chosenFile : null
                }
            },
    
            watch : { 

                chosenFile() {
                    this.keyup(this.item,this.chosenFile,false);
                }
    
            },
    
            methods : {

    
            },
    
    
        }
    
    </script>