<template>
    
<div v-if="currPage">

    <v-dialog
      v-model="dialog"
      :persistent="paramDialog.persistent"
      :fullscreen="($vuetify.breakpoint.xs) ? true : paramDialog.fullscreen"
      :width="paramDialog.width"
      :transition="paramDialog.transition"
      :retain-focus="false"
      @click:outside="closeOutSide"
    >
<OverLay/>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <span v-if="currPage.propsPage.displayname">
            {{replaceBy({text : currPage.propsPage.displayname ,page:currPage.propsPage.props.page})}}
          </span>
          <span v-else>{{pageName}}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closemodal"><v-icon>mdi-close-thick</v-icon></v-btn>      
        </v-card-title>

        <v-card-text>

          <!-- on met le v-if=dialog pour forcer le rechargement du component -->
         <component v-if="dialog" v-bind:is="component" :page="page" :modal="true" :closeModal="closemodal"></component>

         </v-card-text>


      </v-card>
    </v-dialog>
  </div>

</template>



<script>

    export default {
        name :'ModalItem',
        props : ['pageTemplate','visible'],
        components : {
          OverLay: () => import('../../outils/overlay.vue')

        },
        data: function() {
            return {
                page: 0,
                type:'',
                url : '',
                component : '',
                pageName : '...',
                paramDialog : {},
                paramsPage : {},
                currPage : false,
            } 
        },

        computed : {

          dialog: {
                get () {
                  return this.visible
                },

          },
 
        },
        watch : {

            visible(val) {

              if(val) {

      
                  this.paramsPage = this.$store.getters.modalShowParams({type:'from',val:this.pageTemplate});
                  
                  this.currPage = this.$store.getters.modalShowParams({type:'to',val:this.paramsPage.to})
                      // on va lancer les propriétés qui vont construire les éléments dans la modal :
                        this.page = this.paramsPage.propsPage.props.page
                        this.type = this.paramsPage.propsPage.props.type
                        this.args   = this.paramsPage.params
                        this.url  = this.paramsPage.url
                        this.pageName = this.paramsPage.propsPage.displayname
                        this.paramDialog = this.paramsPage.paramDialog
                        this.getComponent()
                      // et dans la foulé on va instancier la nouvelle modal de cette nouvelle page :

                      this.$store.dispatch('initModal',{page:this.paramsPage.propsPage.props.page})
                      }

            },
        },

        methods : {

            closemodal : function() {

              this.$store.dispatch('closeModal',{page:this.pageTemplate})
                this.page = 0

            },
            closeOutSide() {
              if(!this.paramDialog.persistent) this.$store.dispatch('closeModal',{page:this.pageTemplate})
            },

            getComponent() {
              this.component = this.getTemplate({type:this.type})
            }

        },

        

        mounted() {

        },
        created() {

        }

    }

</script>