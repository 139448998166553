export default {

    props: ['item', 'keyup', 'change', 'valField', 'type', 'page', 'modal', 'storeTemplate', 'keyTemplate', 'hook'],

    data: function() {
        return {
            // pour les select et autocomplete, permet de cibler la clé qui sera défini pour l'envoi dans le forumaire
            defaultValue: 'value',
            // array des infos envoyé dans vue-router
            args: [],
            // propriétésde champ : show, disabled etc..
            prop: {},
            // permet de savoir si ce champ a fait l'objet d'un changement de comportement (directive)

            // valeur du champ
            model: '',
            maxCaracter: [],
            maxWords: [],
            required: [],
            modelRules: [],
            //pour les select et autocomplete : liste les options
            items: this.item.liste,

            //permet de déterminer si le click vient de ce champ ou pas.
            formThisField: false,

            //permet degérer le clickdes icones:
            addIcon: false,
            positionAddIcon: ''
        };
    },

    computed: {
        closeChildModal() {
            return this.$store.getters.modalShowParams({ type: 'from', val: this.page })
        }
    },


    watch: {

        closeChildModal: {
            // permet de rafraichir le select ou l'autocomplete quand la modal enfant est un formulaire d'ajout pour ce select
            handler(val) {

                if (val && val.show === false
                    //si l'option d'ajout est faite
                    &&
                    (this.item.options && this.item.options.add.set)
                    //si c'est bien le champ d'ou provient le formulaire dans la modal :
                    &&
                    this.formThisField === true
                ) {

                    //si la dernière sauvegarde a été faite et que l'option provient de la table DB
                    if (this.$store.getters.getLastStored && this.item.options.from == 'DB') {
                        //on prend le numéro de page "to" car c'est la page enfant qui tient le form que l'on veut récup.
                        let LST = this.$store.getters.getLastStored[val.to];
                        if (LST && LST.id) {
                            //this.change(this.item, LST.id, this.defaultValue)
                            //newvalue est exclusivement utilisé ici car quand une nouvelle ligne est créé à la volé, on doit recharger les options
                            //avant de changer la valeur du champ. notammenent pour que les ventilations marchent
                            this.applySpecificCondition({ type: 'reloadOptions', newvalue: LST.id });
                            this.$store.dispatch('removeLastStored', val.to);
                        }
                    }
                    if (this.item.options.from == 'array') {
                        this.$toast.success('Liste modifiée, Veuillez sélectionner votre choix')
                    }

                    this.formThisField = false;

                }

            },
            deep: true

        },

        item: {

            handler(val) {
                if (val.defaultprops != this.prop) this.ApplyProps(val.defaultprops)
            },
            deep: true
        },

        valField(val) {
            this.model = val;
            this.changeStoreOnField({ item: this.item, model: this.model, page: this.page });

        },

    },

    methods: {

        clickIcon() {
            // on récupère les variables donnés pour envoyer dans la page
            var params = { id: 'new' }
            var query = {}
            this.item.options.add.params.forEach((p) => {
                if (p.type && p.type == 'query') {
                    query[p.key] = this.replaceBy({ text: p.value, page: this.page });
                } else {
                    params[p.key] = this.replaceBy({ text: p.value, page: this.page });
                }

            })
            if (this.item.options.from == 'DB') {
                this.goTo({
                    rootPage: this.page,
                    page: this.item.options.add.page,
                    type: 'modal',
                    replace: params,
                    query: query,
                    paramDialog: this.item.options.add.paramdialog,
                    fromModule: this.idmodule
                })
            }

            if (this.item.options.from == 'array') {
                this.$store.dispatch('setActivTab', { hook: 'FieldsDisplay', tab: 'listederoul' })
                this.goTo({
                    rootPage: this.page,
                    type: 'modal',
                    page: 'editionvariant',
                    replace: { id: this.item.id, variant: this.$store.getters.currentUser.cpnID },
                    paramDialog: { width: 800 },
                })
            }
            this.formThisField = true
        },

        clickOnPosition(position) {
            switch (position) {
                case 'append':
                    if (this.positionAddIcon == 'rightin') this.clickIcon();
                    break;
                case 'prepend':
                    if (this.positionAddIcon == 'leftout') this.clickIcon();
                    break;
                case 'append-outer':
                    if (this.positionAddIcon == 'rightout') this.clickIcon();
                    break;
                case 'prepend-inner':
                    if (this.positionAddIcon == 'leftin') this.clickIcon();
                    break;

            }

        },

        applyRules(cle, value) {

            switch (cle) {

                case 'required':
                    if (value === false || this.type == 'search') { this.required = [] } else {
                        this.required = [v => !!v || this.item.name + ' est requis']
                    }

                    break;

                case 'max_caracter':
                    if (value == "" || value == null) { this.maxCaracter = [] } else {
                        this.maxCaracter = [v => v.length <= value || 'Maximum ' + value + ' caractères']
                    }

                    break;

                case 'max_words':
                    if (value == "" || value == null) { this.maxWords = [] } else {
                        this.maxWords = [v => v.trim().split(' ').length <= value || 'Maximum ' + value + ' mots']
                    }

                    break;
            }
            this.prop.modelRules = [...this.maxCaracter, ...this.maxWords, ...this.required]
        },


        //permet de connaitre quelle clé va faire l'objet du store dans le formulaire
        getDefaultValue(items) {
            //on met le this.item.liste.length>0 car la liste peut etre générée par l'API et n'est pas encore crée au moment de l'affichage du select
            if (items && items.length > 0) {
                if (typeof items[0]['default'] !== 'undefined') this.defaultValue = items[0]['default']



                /* en attente/ si la liste a été généré par un autre champ en fonction d'une directive et qu'une valeur a été séléctionné avant,
                // et si cette valeur n'est ps présente lors du chargement, 
                // il faut que cette valeur soit visible dans cette liste, on doit donc créer une <option> avec la valeur correspondante :
                var create = false
                items.forEach((v) => {
                    if (v[this.defaultValue] == this.model) create = true;
                })
                if (!create && this.model != '') {
                    let newOption = {}
                    newOption['text'] = this.model;
                    newOption[this.defaultValue] = this.model;
                    items.push(newOption)
                } */
            }
        }
    },

    mounted() {

        this.model = this.valField

        if (!this.modal) {
            this.args = this.$route.params;
        } else {
            this.args = this.$store.getters.modalShowParams({ type: 'to', val: this.page }).params
        }

        this.getDefaultValue(this.item.liste)

        //permet de mettre en place l'icone si il ya (surtout pour les select et autocomplete)
        if (this.item.options && this.item.options.add.set && this.type == 'form') this.addIcon = this.item.options.add.icon
        if (this.item.options && this.item.options.add.position) this.positionAddIcon = this.item.options.add.position
    },

    created() {
        this.ApplyProps(this.item.defaultprops, true);
    }


}