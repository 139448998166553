<template>
    
<div>
    <v-container v-if="checkAPI">
<v-row>
    <v-col>
        <v-btn icon x-small>                
              <v-icon
                @click="
                  goTo({
                    rootPage: page,
                    page: 'editiondroit',
                    type: 'modal', 
                    replace: {root:'general'},
                  })
                "
                >mdi-account-eye-outline</v-icon>
            </v-btn>
    </v-col>
</v-row>

<v-row v-if="!error && message">
    <v-col class="red lighten-3 rounded pa-5 text-center">
        {{ message }}
    </v-col>
</v-row>

<v-row v-if="error && message">
    <v-col class="green lighten-3 rounded pa-5 text-center">
        {{ message }}
    </v-col>
</v-row>

<v-row>
    <v-col>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
                <v-row>
                    <v-col >
                        <TextField 
                            :item="{value:'name',name:'Nom',defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide'],outlined:true,required:true}}"
                            :keyup="keyUp" 
                            :valField="name" /> 
                    </v-col>
                    <v-col>
                        <TextField 
                            :item="{value:'nickName',name:'Prénom',defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide'],outlined:true,required:true}}" 
                            :valField="nickName" 
                            :keyup="keyUp"/> 
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Select                          
                        :change="change"
                        :item="{name:'Droit appliqué pour cet utilisateur',value:'groupe',liste:listGroupe,
                        defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide'],required:true}}" 
                        :valField="groupe" /> 
                    </v-col>            
                </v-row>
                <v-row>
                    <v-col>
                        <TextField 
                            :item="{value:'email',name:'adresse e-mail',format:'email',
                            defaultprops:{show:true,modelRules:emailRules,outlined:true,required:true,
                            description:'Un e-mail sera envoyé à cette adresse afin de valider son compte. La personne devra définir son mot de passe',
                            persistenthint:true 
                            }}"
                            
                            :keyup="keyUp"
                            :valField="email" /> 
                    </v-col>            
                </v-row>
                <v-row>
                    <v-col>
                        <TextField 
                            :item="{value:'mobile',name:'N° Mobile',format:'email',
                            defaultprops:{show:true,modelRules:emailRules,outlined:true,required:true,
                            persistenthint:true 
                            }}"
                            
                            :keyup="keyUp"
                            :valField="mobile" /> 
                    </v-col>            
                </v-row>
                <v-row>
                    <v-col align="center">
                        <v-btn 
                @click="submitForm" 
                >
                Créer un nouvel utilisateur
                
                </v-btn>                
                    </v-col>
                </v-row>
        </v-form>
    </v-col>
</v-row>
    </v-container>

    <v-container v-else>
        <v-row>
            <v-col>
                {{isAllow}}
            </v-col>
        </v-row>
    </v-container>
</div>

</template>

<script>

export default {

name : "createUser",
props: [
    "page",
    "modal",
  ],
components : {
    TextField   : () => import('../../fields/textfield.vue'),
    Select      : () => import('../../fields/select.vue'),
    },

    data: function() {
        return {
            name: '',
            nickName : '',
            email : '',
            mobile : '',
            groupe : '',
            listGroupe : [],
            allowCreate : false,
            checkAPI : false,
            isAllow : 'En attente du module',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                ],
            valid : true,

            message : '',
            error : false
        }
    }, 



    methods: {
        keyUp(item,value) {

            this[item.value] = value
        },

        change(item,model,selectedKey) {
            this[item.value] = model[selectedKey]
        },

        submitForm () {
            if(this.$refs.form.validate()) {
   
                // création de l'utilisateur
                this.API({type:'post', url:'/engine/manageuser/register', 
                params:{ 
                    name : this.name, 
                    nickname : this.nickName, 
                    email : this.email, 
                    mobile : this.mobile,
                    groupe : this.groupe}}).then((resp) => {
                    this.message = resp.data.message
                    
                    this.error = resp.data.success
                })                

            }
        },
    },

    created() {

        // on check si l'utilisateur peut créer un nouvel utilisateur :
        this.API({type:'post', url:'/engine/droits/getdroit',params:{core:'register',root:'general'}}).then((resp) => {
                this.checkAPI = resp.data
                
                this.listGroupe = resp.data
                if(resp.data === false) this.isAllow = 'non autorisé';
        })

    },


}
</script>