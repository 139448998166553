<template>
    <v-row>
<v-col>

                    <Routing 
                    v-for="(rowitem,rowindex) in champs" 
                    :key="rowindex"
                    :item="rowitem"
                    :event="event"
                    />

    </v-col>
    </v-row>
</template>

<script>

export default {

        name : 'modConstructEncart',

        props:['champs','event'],

        components : {
            Routing :() => import('./Routing'),
        }







}

</script>