
<template>

    <v-main :class="[hook ? 'pa-0 ma-0' : '']">    
      <!-- on met main a true afin de savoir si l'le store modal va etre remis à zero (question d'alléger l'array) -->
    <component v-bind:is="component" :page="page" :hook="hook" :modal="false"></component>
  
  </v-main> 

</template>

<script>

  export default {
      name : 'MainComponent',
        // tous ces params viennent du router
        props: ['type','page','hook'],
        
             
        data: function() {
            return {

                component: '' ,
                
 
            }
        },

        methods : {

            getComponent() {

                this.component = this.getTemplate({type:this.type})
                
            },

        },
        mounted() {
          this.getComponent()
          this.$store.dispatch('initModal',{page:this.page})
          
        },


  }
</script>