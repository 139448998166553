var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TextField',{attrs:{"item":{ name: 'Classe', value: 'class', defaultprops:{ show: true }},"valField":_vm.displays.params.class,"keyup":_vm.changeParams}}),_c('colorPicker',{attrs:{"item":{ name: 'Couleur de fond', value: 'bgcolor' , defaultprops:{ show: true }},"valField":_vm.displays.params.bgcolor,"change":_vm.changeParams}}),_c('v-row',[_c('v-col',[_c('SwitchC',{attrs:{"item":{ name: 'dark mode', value: 'dark' , defaultprops:{ show: true }},"valField":_vm.displays.params.dark,"change":_vm.changeParams}})],1),_c('v-col',[_c('SwitchC',{attrs:{"item":{ name: 'position center', value: 'centered', defaultprops:{ show: true }},"valField":_vm.displays.params.centered,"change":_vm.changeParams}})],1),_c('v-col',[_c('SwitchC',{attrs:{"item":{ name: 'position droite', value: 'right', defaultprops:{ show: true }},"valField":_vm.displays.params.right,"change":_vm.changeParams}})],1),_c('v-col',[_c('SwitchC',{attrs:{"item":{ name: 'tabs vertical ?', value: 'vertical', defaultprops:{ show: true }},"valField":_vm.displays.params.vertical,"change":_vm.changeParams}})],1),_c('v-col',[_c('SwitchC',{attrs:{"item":{ name: 'laiser place à une icone dans les tabs ?', value: 'iconText', defaultprops:{ show: true }},"valField":_vm.displays.params.iconText,"change":_vm.changeParams}})],1)],1),_c('v-btn',{attrs:{"fab":"","small":"","disabled":!_vm.module.id},on:{"click":function($event){return _vm.goTo({
                rootPage: _vm.page,
                page: 'editiontabulation',
                type: 'modal',
                replace: { id: 'new', idmodule: _vm.module.id },
              })}}},[_c('v-icon',[_vm._v("mdi-database-plus-outline")])],1),_c('SimpleTable',{attrs:{"Headers":[
                  { text: 'Intitulé', value: 'name' },
                  { text: 'Ordre', value: 'order' }
                ],"datas":_vm.listTabs,"editTable":[{ icon: 'mdi-pencil', action: _vm.editTabs }],"del":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }