<template>
  <div>
    <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
      <v-tab> Paramètres généraux </v-tab>
      <v-tab> Filtre calendrier et variables </v-tab>
      
      <v-tab> requete pour affichage </v-tab>

      <v-tab> affichage </v-tab>

      <v-tab> Actions </v-tab>

        <!--
          PARAM GEN.
          -->
      <v-tabs-items v-model="tab" class="pl-4">
        <v-tab-item>
          <v-card flat>
            <v-row>
              <v-col>

                <Select class="mt-3" :item="{
                  defaultprops:{ show: true, column: false },
                  name: 'Affichage par défaut',
                  value: 'initialView',
                  liste: [
                    { text: 'Semaine', value: 'timeGridWeek' },
                    { text: 'Jour', value: 'timeGridDay' },
                    { text: 'Mois', value: 'dayGridMonth' },
                  ],
                }" :change="changeVue" :valField="displays.params.vue.initialView" />

                <Autocomplete :item="{
                  defaultprops:{ show: true, chips:true,multiple:true,deletablechips:true },
                  name: 'Ne pas afficher le',
                  value: 'hiddenDays',
                  liste: [
                    { text: 'Dimanche', value: 0 },
                    { text: 'Lundi', value: 1 },
                    { text: 'Mardi', value: 2 },
                    { text: 'Mercredi', value: 3 },
                    { text: 'Jeudi', value: 4 },
                    { text: 'Vendredi', value: 5 },
                    { text: 'Samedi', value: 6 },
                  ],
                }" :change="changeVueMultiple" :valField="displays.params.vue.hiddenDays" />

                    <TextField 
                    :item="{name:'heure de début',value:'minslot',
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" 
                    :keyup="changeVue"
                    :valField="displays.params.vue.minslot" />

                    <TextField  
                    :item="{name:'heure de fin',value:'maxslot',
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" 
                    :keyup="changeVue"
                    :valField="displays.params.vue.maxslot" />

                    <SwitchC
                    :item="{name: 'afficher la barre des heures',value: 'hoursbar',
                    defaultprops:{ show: true, column: false }}"
                    :change="changeVue"
                    :valField="displays.params.vue.hoursbar"
                    /> 

                    <Autocomplete
                    :item="{ value: 'editpage', name: 'page de modification :', liste: listPages,
                    defaultprops:{ show: true,chips:false,deletablechips:false } }"
                    :change="changeVue" 
                    :valField="displays.params.vue.editpage"
                    />

                    <Autocomplete
                    :item="{ value: 'newpage', name: 'page de création :', liste: listPages,
                    defaultprops:{ show: true,chips:false,deletablechips:false } }"
                    :change="changeVue" 
                    :valField="displays.params.vue.newpage"
                    />


                    

              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <!--
          FILTRE DE DATE ET AUTRE
          -->

        <v-tab-item>

          <v-row>
            <v-col class="col-12 col-md-6">
              <!-- 
                // creation de la liste déroulante pour affichage au dessus du calendrier
                //
              -->
          <div class="text-h6 text-decoration-underline">Option de filtre sur calendrier</div>

          <SimpleTable
          v-if="dataTableSearch.length!=0"
                :Headers="[
                  { text: 'ID', value: 'id' },
                  { text: 'champs', value: 'champs' },
                ]"
                :datas="dataTableSearch"
                :selected="displays.params.searchmodule"
                keyRow="id"
                @getselected="changeSearchModule"
              />
          </v-col>

          <v-col class="col-12 col-md-6"> 
              <!-- 
                //
                //
                // creation des variables d'insertion, et d'envoi sur calendrier
                //
              -->
            <div class="text-h6 text-decoration-underline">Relationnels des champs de la table {{ module.table }}</div>


<div class="font-weight-bold">Insérez la clé des champs correspondants au start, end, allday et view</div>

<ul class="font-italic">
  <li><strong>obligatoire</strong> Valeur pour date de début : <strong>[[start]]</strong></li>
  <li><strong>obligatoire</strong> Valeur pour date de fin : <strong>[[end]]</strong></li>
  <li><strong>obligatoire</strong> Valeur pour l'Option de filtre sur calendrier : <strong>[[filter]]</strong></li>
  <li><strong>obligatoire</strong> Valeur pour le filtre "la journée", ou "créneau horaire" : <strong>[[allday]]</strong></li>
</ul>          

            <ArrayTable 
            :item="{otherparams:{arrayTable:[{type:'select',key:'key',name:'Clé de la table',ArrayValue:fieldsInModuleTable},{type:'select',key:'type',name:'type de donnée',ArrayValue:[{text:'nombre',value:'number'},{text:'texte',value:'text'},{text:'booleen',value:'bool'}]},{type:'',key:'value',name:'Valeur du GET',description:'(Vous pouvez insérer des variables d\'environnement)'}]},
            defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'Ajouter une variable'}}"
            :valField="displays.params.vue.sendget"
            :change="changeSendGet"
            />
<span class="font-weight-bold">Variables extérieures (aussi envoyées sur l'insert)</span>
<ul class="font-italic">
<li>clé <strong>"gotodate"</strong> (facultatif) : obligation de formatage en 'yyyy-mm-dd'. si défini, le calendrier sera redirigé sur la semaine de la date en question lors du premier chargement</li>
<li>clé <strong>"defaultview"</strong> (facultatif) : représente une valeur du champ de l'option de filtre ([[view]]). si défini, il supplentera la valeur [[view]] lors du premier chargement du calendrier</li>
<li><strong>Tout autre variable</strong> venu de l'extérieur. La variable envoyée doit correspondre à la clé d'un champ dans l'édition de l'event,  (si la variable est un nombre, il sera automatiquement traduit en valeur nombre, non en string)</li>
</ul>              
</v-col>
  </v-row>
        </v-tab-item>
        <!--
          //
          //
          REQUETES
          //
          //
          -->
        <v-tab-item>
            <v-expansion-panels variant="popout" v-model="accordionQuery" class="pa-3">
              <v-expansion-panel>
                <v-expansion-panel-header>champs</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Fields :page="page" :champs="displays.params.champs" :filter="displays.filter" :table="module.table" />
                </v-expansion-panel-content>
                
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>filtre</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Filtre :page="page" :filtre="displays.filter" :table="module.table" />
                </v-expansion-panel-content>                
              </v-expansion-panel>
            </v-expansion-panels>
          


        </v-tab-item>
        <!--
          //
          //
          AFFICHAGE ENCART ET TOOLTIPS
          //
          //
          -->
        <v-tab-item>

            <v-tabs v-model="aff">
              <v-tab> encart </v-tab>
              <v-tab> tooltip </v-tab>
              <v-tab> actions </v-tab>

              <v-tabs-items v-model="aff">
        <!--
          //
          ENCART 
          //
          -->
                  <v-tab-item>
                    <p>Vous pouvez inclure dans les styles et valeurs, la variable d'environnement [[line||'nom_du_as']]</p>
                    <v-btn fab small @click="addVariant('encart')"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panels variant="popout" v-model="accordionEncart" class="pa-3">
              <v-expansion-panel v-for="(encart, i) in displays.params.display.encart" :key="i">
                <v-expansion-panel-header>
                  {{encart.key}}
                  <template v-slot:actions>
                    <v-btn icon @click="removeVariantEncart(i,'encart')" v-if="encart.key!='default'"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template>                 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">
                    <TextField 
                    :item="{name:'couleur de l\'encart',value:'color',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'encart')}"
                    :valField="encart.color" />
                    <TextField 
                    :item="{name:'couleur du contour de l\'heure',value:'colorcontourtime',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'encart')}"
                    :valField="encart.colorcontourtime" />
                    <TextField 
                    :item="{name:'couleur de la barre de l\'heure',value:'colortime',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'encart')}"
                    :valField="encart.colortime" />
                  <div v-if="encart.key!='default'">
                    <TextField 
                    :item="{name:'nom du variant',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'encart')}"
                    :valField="encart.key" />
                    Nouvelle condition <v-btn fab small @click="addCondition(i,'encart')"><v-icon>mdi-plus</v-icon></v-btn>  
                      <v-row v-for="(cond,c) in encart.conditions" :key="c" no-gutters class="elevation-1 blue lighten-5">
                        <v-col>
                          <Select :item="{
                            defaultprops:{ show: true,modelRules:[v => !!v || 'valeur requise']},
                            name: 'Champ',
                            value: 'champ',
                            liste: selectableFields,
                          }" :change="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'encart') }" :valField="cond.champ" />
                          </v-col>
                          <v-col>
                          <Select :item="{
                            defaultprops:{ show: true,modelRules:[v => !!v || 'valeur requise']},
                          name: 'signe',
                          value: 'signe',
                          liste: [{text:'égale à',value:'='},{text:'différent de',value:'!='}],
                          }" :change="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'encart') }" :valField="cond.signe" />
                        </v-col>
                        <v-col>
                        <TextField 
                        :item="{name:'valeur',value:'value',
                        defaultprops:{show:true,modelRules:[v => !!v || 'valeur requise']}}" 
                        :keyup="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'encart') }"
                        :valField="cond.value" />   
                        </v-col> 
                        <v-col>
                          <v-btn icon @click="removeCondition(i,c,'encart')"><v-icon>mdi-close-thick</v-icon></v-btn>
                        </v-col>                  
                      </v-row>

                  </div>
                  <constructEncart :champs="encart.construct" :page="page" :selectable="displays.params.champs" />
                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>


                  </v-tab-item>
        <!--
          //
          TOOLTIP 
          //
          -->
                  <v-tab-item>
                    <p>Vous pouvez inclure dans les styles et valeurs, la variable d'environnement [[line||'nom_du_as']]</p>
                    <v-btn fab small @click="addVariant('tooltip')"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panels variant="popout" v-model="accordionTooltip" class="pa-3">
              <v-expansion-panel v-for="(tooltip, i) in displays.params.display.tooltip" :key="i">
                <v-expansion-panel-header>
                  {{tooltip.key}}
                  <template v-slot:actions>
                    <v-btn icon @click="removeVariantEncart(i,'tooltip')" v-if="tooltip.key!='default'"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template>                 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">
                  <v-row no gutters>
                    <v-col>
                    <TextField 
                    :item="{name:'largeur minimum',value:'minwidth',format:'number',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'tooltip')}"
                    :valField="tooltip.minwidth" />
                    </v-col>
                    <v-col>
                      <TextField 
                    :item="{name:'position tooltip',value:'position',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'tooltip')}"
                    :valField="tooltip.position" />
                    </v-col>
                  </v-row>

                  <div v-if="tooltip.key!='default'">
                    <TextField 
                    :item="{name:'nom du variant',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'tooltip')}"
                    :valField="tooltip.key" />
                    Nouvelle condition <v-btn fab small @click="addCondition(i,'tooltip')"><v-icon>mdi-plus</v-icon></v-btn>  
                      <v-row v-for="(cond,c) in tooltip.conditions" :key="c" no-gutters class="elevation-1 blue lighten-5">
                        <v-col>
                          <Select :item="{
                            defaultprops:{ show: true,modelRules:[v => !!v || 'valeur requise']},
                            name: 'Champ',
                            value: 'champ',
                            liste: selectableFields,
                          }" :change="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'tooltip') }" :valField="cond.champ" />
                          </v-col>
                          <v-col>
                          <Select :item="{
                          defaultprops:{ show: true,modelRules:[v => !!v || 'valeur requise']},
                          name: 'signe',
                          value: 'signe',
                          liste: [{text:'égale à',value:'='},{text:'différent de',value:'!='}],
                          }" :change="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'tooltip') }" :valField="cond.signe" />
                        </v-col>
                        <v-col>
                        <TextField 
                        :item="{name:'valeur',value:'value',defaultprops:{show:true,modelRules:[v => !!v || 'valeur requise']}}" 
                        :keyup="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'tooltip') }"
                        :valField="cond.value" />   
                        </v-col> 
                        <v-col>
                          <v-btn icon @click="removeCondition(i,c,'tooltip')"><v-icon>mdi-close-thick</v-icon></v-btn>
                        </v-col>                  
                      </v-row>

                  </div>
                <constructTooltip :champs="tooltip.construct" :page="page" :selectable="displays.params.champs" />
                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>

                  </v-tab-item>
          <!--
          //
          condition d'action 
          //
          -->   
                  <v-tab-item>
                <v-btn fab small @click="addVariant('actions')"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panels variant="popout" v-model="accordionAction" class="pa-3">
              <v-expansion-panel v-for="(action, i) in displays.params.display.actions" :key="i">
                <v-expansion-panel-header>
                  {{action.key}}
                  <template v-slot:actions>
                    <v-btn icon @click="removeVariantEncart(i,'actions')" v-if="action.key!='default'"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template>                 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">

                  <div>
                    <TextField 
                  
                    v-if="action.key!='default'"
                    :item="{name:'nom du variant',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'actions')}"
                    :valField="action.key" />
                    Nouvelle condition <v-btn fab small @click="addCondition(i,'actions')"><v-icon>mdi-plus</v-icon></v-btn>  
                      <v-row v-for="(cond,c) in action.conditions" :key="c" no-gutters class="elevation-1 blue lighten-5">
                        <v-col>
                          <Select :item="{
                            defaultprops:{ show: true,modelRules:[v => !!v || 'valeur requise']},
                            name: 'Champ',
                            value: 'champ',
                            liste: selectableFields,
                          }" :change="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'actions') }" :valField="cond.champ" />
                          </v-col>
                          <v-col>
                          <Select :item="{
                          defaultprops:{ show: true,modelRules:[v => !!v || 'valeur requise']},
                          name: 'signe',
                          value: 'signe',
                          liste: [{text:'égale à',value:'='},{text:'différent de',value:'!='},{text:'est vide',value:'empty'},{text:'est pas vide',value:'notempty'}],
                          }" :change="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'actions') }" :valField="cond.signe" />
                        </v-col>
                        <v-col>
                        <TextField 
                     
                        :item="{name:'valeur',value:'value',defaultprops:{show:true,modelRules:[v => !!v || 'valeur requise']}}" 
                        :keyup="(item,value,selectedKey) => { changeConditon(item,value,selectedKey,i,c,'actions') }"
                        :valField="cond.value" />   
                        </v-col> 
                        <v-col>
                          <v-btn icon @click="removeCondition(i,c,'actions')"><v-icon>mdi-close-thick</v-icon></v-btn>
                        </v-col>                  
                      </v-row>

                  </div>

                  <Autocomplete :item="{
                  defaultprops:{ show: true, chips:true,multiple:true,deletablechips:true },
                  name: 'actions affichés',
                  value: 'none',
                  liste: listActions,
                  }" :change="(item,value,sel)=> {changeActions(item,value,sel,i) }" :valField="action.actions" />

                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>

                  </v-tab-item>
              </v-tabs-items>
            </v-tabs>


        </v-tab-item>

        <!--
          //
          ACTIONS 
          //
          -->
        <v-tab-item>

          <listActions :page="page" :module="module" @listActions="Actions"/>

        </v-tab-item>

      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>

export default {
  name: "CalendarComponent",
  props: ["module", "page"],
  components: {
    Autocomplete: () => import("../../../fields/autocomplete.vue"),
    TextField: () => import("../../../fields/textfield.vue"),
    Filtre: () => import("../../outils/constructFiltre/Filtre.vue"),
    Fields: () => import("../../outils/constructField/Field.vue"),
    Select: () => import("../../../fields/select.vue"),
    ArrayTable   : () => import('../../../fields/arrayTable.vue'),
    constructEncart   : () => import('./constructEncart/encart.vue'),
    constructTooltip   : () => import('./constructPage/index.vue'),
    listActions: () => import("../../outils/listActions.vue"),
    SimpleTable: () => import("../../outils/simpleTable.vue"),
    SwitchC: () => import("../../../fields/switch.vue"),
  },
  data: function () {
    return {
      tab: 0,
      aff : true,
      accordionEncart : false,
      accordionTooltip : false,
      accordionAction : false,
      accordionQuery : false,
      panel2 : false,
      listPages : [],
      listTables : [],
      fieldsInTable : [],
      fieldsInModuleTable : [],
      paramDefaultView : false,
      listActions : [],
      displays: this.module.displays,
      selectableFields : [],
      newCondition : {champ:'',signe:'',value:''},
      dataTableSearch : [],
    };
  },

  watch : {
        'displays.params.champs' : {
            handler(val) { 
            this.selectableFields = []
                val.forEach((v)=>{
                    this.selectableFields.push({text:v.as,value:v.as})
                }) 
            }, deep:true           
        }
  },
  mounted() {

        // récupère toutes les pages pour le choix du form d'insertion et de modification
        this.API({ type: 'get', url: 'allpage' }).then((resp) => {

            this.listPages.push({text:'aucune',value:null});
            resp.data.forEach((page) => {
                if(page.type=='form' && page.id!=this.page) {
                this.listPages.push({text:page.displays.displayname+" ("+page.name+")",value:page.name});
                }
            })

        })
        //récupère toutes les tables pour le choix du champ pour la liste déroulante au dessus du calendrier
        this.API({type:'get', url:'engine/getlisttable'}).then((resp) => {
            resp.data.forEach((table) => {
                this.listTables.push({text:table,value:table})
            })
            
        })
        //récupère tous les champs pour la liaison date début et fin de la table du module
        this.getListField({filter:{table:this.module.table}}).then((resp) => {      
  
                  if(Array.isArray(resp)) {
                      resp.forEach((field) => {
                          this.fieldsInModuleTable.push({"text":field.displays.name,"value":field.cle})
                      })                             
                  }                                                
                })  
        
        // on récupère la liste des champs de la requete pour alimenter le champs des conditions d'encart et de tooltip:
            this.displays.params.champs.forEach((v)=>{
                this.selectableFields.push({text:v.as,value:v.as})
            }) 

      this.API({type:'post', url:'/engine/module/actions' , params:{module:this.module.id}}).then((resp) => {       
        this.Actions(resp.data);
      });  
    
        this.getsearchFromAPI();

  },


  methods: {

    Actions(datas){
      this.listActions = []
      datas.forEach((v) => {
        this.listActions.push({text:v.name,value:v.id});
      })
    },

    changeActions(item,value,selectedKey,row) {
      var actions = []
      for(var selected in value) {
        if(typeof value[selected] == 'object') { actions.push(value[selected][selectedKey]) } else { actions.push(value[selected]); }
      }
      this.displays.params.display.actions[row].actions = actions
      this.$emit('recepVal',this.displays); 
    },

        //##########################
        //
        //Fonctions pour les PARAMS. GENERAUX
        //###########################

    changeVue(item, value,selectedKey) {
      var res = (typeof value ==='object') ? value[selectedKey] : value 

      this.displays.params.vue[item.value] = res;

      this.$emit('recepVal',this.displays);
    },

    changeSendGet(item,value) {
      this.displays.params.vue.sendget = value;

      this.$emit('recepVal',this.displays);
    },

    changeVueMultiple(item, value,selectedKey) {
            var res = []
                for(var selected in value) {
                if(typeof value[selected] == 'object') { res.push(value[selected][selectedKey]) } else { res.push(value[selected]); }
                }        
    this.displays.params.vue[item.value] = res;
      this.$emit('recepVal',this.displays);
    },

    changeListFormat(datas) {
      this.displays.params.vue.selectionliste = datas;
      this.$emit('recepVal',this.displays);
    },

    getsearchFromAPI() {
      this.API({type:'post', url:"/engine/module/list",params:{type:'search'}}).then((resp) => {

        resp.data.forEach((v) => {
          this.dataTableSearch.push({ id: v.id, champs: v.displays.params.champs.join(', ') });
        });
      });
    },

    changeSearchModule(val) {
      this.displays.params.searchmodule = val;
      this.$emit('recepVal',this.displays)
    },


              //##########################
              //
              //Fonctions pour les ENCART
              //###########################

    addVariant(enctool) {
      var defaultEncart = this.displays.params.display[enctool].find((el) => el.key ==='default')
      let copieDefault = JSON.parse(JSON.stringify(defaultEncart))
      copieDefault.key="nouveau variant"
      this.displays.params.display[enctool].push(copieDefault);
      this.$emit('recepVal',this.displays);
    },
    removeVariantEncart(key,enctool) {
      this.displays.params.display[enctool] = this.displays.params.display[enctool].filter((el,i)=> i!=key);
      this.$emit('recepVal',this.displays);
    },

    changeNameVariantEncart(item,value,selectedKey,key,enctool) {
      var res = (typeof value ==='object') ? value[selectedKey] : value
      this.displays.params.display[enctool][key][item.value] = res
      this.$emit('recepVal',this.displays);
    },

    addCondition(row,enctool) {
      let newCondition = JSON.parse(JSON.stringify(this.newCondition));
      this.displays.params.display[enctool][row]['conditions'].push(newCondition)
    },

    changeConditon(item,value,selectedKey,row,rowcondition,enctool) {
      var res = (typeof value ==='object') ? value[selectedKey] : value
      this.displays.params.display[enctool][row]['conditions'][rowcondition][item.value] = res
      this.$emit('recepVal',this.displays);
    },

    removeCondition(row,rowcondition,enctool) {
      this.displays.params.display[enctool][row]['conditions'] = this.displays.params.display[enctool][row]['conditions'].filter((el,i)=> i!=rowcondition);
    }

  },
};
</script>
