<template>
<div v-if="field">

<v-form 
    ref="form"
    v-model="valid"
    lazy-validation
>
<v-row>

    <v-col>
        <v-row>
            <v-col class="col-12 col-md-6">

                <Select 
                :item="{value:'isreal',name:'Champ réel',liste:[{text:'oui',value:1},{text:'non',value:0}],defaultprops:{show:true,disabled:true}}"
                :change="changeParam"
                :valField="field.isreal"
                />
                <TextField 
                :item="{value:'field',name:'Nom du champ dans la base',defaultprops:{show:true,disabled:true}}"
                :keyup="changeParam"
                :valField="field.field"
                />
                <Select 
                :item="{value:'table',name:'Table liée au champ',liste:listTables,defaultprops:{show:true,modelRules:[v => !!v || 'champ obligatoire'],disabled:field.create}}"
                :change="changeParam"
                :valField="field.table"
                />
            </v-col>
            <v-col class="col-12 col-md-6">

                <TextField 
                :item="{value:'cle',name:'clé de référence',defaultprops:{show:true,modelRules:[v => !!v || 'champ obligatoire'],disabled:field.create}}"

                :keyup="changeParam"
                :valField="field.cle"
                />

                <Select 
                :item="{value:'search',name:'Pour la recherche',liste:listFormat,defaultprops:{show:true}}"
                :change="changeFormat"
                :valField="field.format.search.type"
                />
                <Select 
                :item="{value:'form',name:'Pour les formulaires',liste:listFormat,defaultprops:{show:true}}" 
                :change="changeFormat"
                :valField="field.format.form.type"
                />
            </v-col>
        </v-row>

        <v-tabs background-color="blue lighten-5" v-model="tab">
   
            <v-tab>
                Param. par défaut
            </v-tab> 
            <v-tab>
                Relationnels
            </v-tab>
            <v-tab v-if="this.field.id">
                par compagnie
            </v-tab>

            <v-tabs-items v-model="tab" class="pa-2">

                    <v-tab-item class="pa-1">
                        <!--- PARAM PAR DEFAUT -->
                        <v-card>
   
                        <DefaultParamField @returnDisplay="changeDisplay" :params="$store.getters.listForm(this.page).fields.displays" :page="page" v-if="loaded" :args="args" :field="field"/>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item>
                        
                            <Filtre :table="field.table" :filtre="relationsTable" @recepVal="(data) => { relationsTable = data}"/>                        
                    </v-tab-item>

                    <v-tab-item v-if="this.field.id">


                        <!--- PARAM PAR COMPAGNIE -->
                   <v-row v-for="(etps, i ) in listEntreprises" :key="i" no-gutters>
                    <v-col>{{etps.nom}}</v-col>
                    <v-col>
                        <div >
                            <v-btn icon
                                @click="
                                goTo({
                                rootPage: page,
                                type: 'modal',
                                page:'editionvariant',
                                replace : {id:field.id, variant:etps.id},
                                paramDialog: { width: 1000 },
                                })"
                            >
                                <v-icon v-if="field.listvariant.includes(etps.id)">mdi-pencil</v-icon>
                                <v-icon v-else>mdi-plus</v-icon>
                            </v-btn>
                        </div>

                    </v-col>
                   </v-row>


                    </v-tab-item>

            </v-tabs-items>
        </v-tabs>

        

    </v-col>
</v-row>
    <v-row>
        <v-col class="text-center">
            <v-spacer></v-spacer>
         <v-btn class="primary" @click="validate" :disabled="!valid" >Enregistrer</v-btn>   
        </v-col>
    </v-row>
</v-form>

</div>

</template>


<script>


    export default {
        name : 'FormModule',
        props : ['page','modal'],

        components : {
            Select   : () => import('../../fields/select.vue'),
            TextField   : () => import('../../fields/textfield.vue'),
            DefaultParamField : () => import('./defaultField/defaultParamField.vue'),  
            Filtre   : () => import('../outils/constructFiltre/Filtre.vue'),        

        },

        data: function() {
            return { 

                urlSend : (!this.modal) ? this.$route.path : this.$store.getters.modalShowParams({type:'to',val:this.page}).url,
                valid : false,
                listTables : [],
                displays :false,
                field : false,
                createArrayField : {name:'',type:'',default:''},
                editedIndex : -1,
                dialog : false,
                loaded : false,
                // permet de connaitre l'id qui va etre updaté (si modal on prend les infos de la modal, si page pleine on prend le router.params.id)
                args : (!this.modal) ? this.$route.params : this.$store.getters.modalShowParams({type:'to',val:this.page}),
                tab: null,
                listFormat : [],
                listEntreprises : [],
                relationsTable : [],

            }
        },

  watch: {
    showMod: {
      // permet de rafraichir quand la modal de la page se ferme
      handler(val) {
        if (val.show === false && val.propsPage.name=='editionvariant') {
          this.getFromAPI();
        }
      },
      deep: true,
    },
  },

        methods : {

            validate(){
                var params = this.$store.getters.listForm(this.page).fields;

                if(params.field.format.form.type=='select' && params.displays.liste.length==0) {
                    alert('vous devez remplir des valeurs pour l\'affichage dans les tableaux')
                    return false;
                }
                if(params.displays.name=='' && params.field.format.form.type!='arrayfield') {
                    alert('vous devez remplir un intitulé pour l\'affichage de colonne dans les tableaux')
                    return false;
                }
                 this.$store.dispatch('getoverlay',true)
                 this.API({type:'put', url:this.urlSend, params:{id:this.args.params.id, fields:this.$store.getters.listForm(this.page)['fields'],relationsTable:this.relationsTable,variant : 0}}).then((resp) => {

                        this.field = resp.data.field
                        this.displays = resp.data.displays;
                        
                        this.$store.dispatch('pushForm', { page:this.page, value:resp.data.field, champ:'field' })
                        this.$store.dispatch('pushForm', { page:this.page, value:resp.data.displays, champ:'displays' })
                        this.$store.dispatch('changeOptionsModal',{modal:{type:'to',val:this.page},type:'params',value:{id:resp.data.field.id}});
                        this.$store.dispatch('getoverlay',false)

                    })


            },

            changeFormat(item,value,selectedKey) {
                this.field.format[item.value]['type'] = value[selectedKey]
            },
            changeParam(item,value,selectedKey) {
                this.field[item.value] = value[selectedKey]
            },

            changeDisplay(data) {

                this.displays = data
                this.$store.dispatch('pushForm', { page:this.page, value:this.displays, champ:'displays' })
            },
            
            getFromAPI() {
                this.API({type:'post', url:this.urlSend, params:this.args.params}).then((resp) => {    
                        this.field = resp.data.field
                        this.displays = resp.data.displays;
                        this.relationsTable = resp.data.relationsTable;
                        this.listFormat = resp.data.listTypeFields;
                        this.$store.dispatch('pushFirstForm', { page:this.page, values:{field : resp.data.field, displays : this.displays }});
                        this.loaded = true;
                    })

            },


        },
    
        mounted() {          
        
        this.API({type:'get', url:'engine/getlisttable', params:{}}).then((resp) => { 
                    var that = this
                    resp.data.forEach(function(table){
                        that.listTables.push({text:table,value:table})
                    })
                    
                })
        this.API({type:'post', url:'engine/entreprises/list', params:{}}).then((resp) => { 
                    
                    this.listEntreprises = resp.data
                    
                })

        this.getFromAPI()
        }

    }

</script>