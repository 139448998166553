<template>
    
<v-overlay :value="overlay" :absolute="Absolute" :opacity="Opacity">

  <div class="text-center h3">
  {{Text}}
  </div>

  <div class="text-center">
    <v-progress-circular
      indeterminate
      :color="color"
      :size="Size"
      :width="3"
    ></v-progress-circular>
    </div>
</v-overlay>

</template>



<script>

    export default {
        name : 'OverLay',
        props : ['absolute','colorCircle','sizeCircle','show','text','opacity'],

        data: function() {

            return {
                overlay : false,
                Opacity : (!this.opacity) ? 0.46 : this.opacity,
                color : (!this.colorCircle) ? "primary" : this.colorCircle,
                Absolute : (!this.absolute) ? false : this.absolute,
                Size : (!this.sizeCircle) ? "100" : this.sizeCircle,
                Text : ''
            }
        },
computed : {
  isoverlay() {
    return this.$store.getters['isoverlay']
  },
  isShow() {
    return this.show
  },
  isText() {
    return this.$store.getters.textOverlay
  }
},

watch : {
  isoverlay(val) {
    this.overlay = val
  },
  isShow(val) {
    this.overlay = val
},
isText(val) {
    if(!this.text) {
      this.Text = val
    } else {
      this.Text = this.text
    } 
  }
},
        methods :  {



        },
        mounted() {

        },

        created() {
        
            if(this.show!==false && this.show!==true) {
              this.overlay = this.$store.getters['isoverlay']
            } else {
              this.overlay = this.show
            }

            if(!this.text) {
              this.Text = this.$store.getters.textOverlay
            } else {
              this.Text = this.text
            }            
        }

    }

</script>