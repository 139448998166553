<template>
<div justify="center" align="center">
    <v-hover v-slot="{ hover }">
        <template>
            <v-card width="200" weight="180" >
                    <v-fade-transition>
                        <v-overlay
                        absolute
                        :value="hover"
                        :opacity="0.2"
                        >
                        <v-btn small icon class="primary ma-1" @click="click()"><v-icon>mdi-file-arrow-left-right-outline</v-icon></v-btn>
                        <v-btn small icon class="error ma-1" @click="remove()"><v-icon>mdi-file-remove-outline</v-icon></v-btn>
                        </v-overlay>
                    </v-fade-transition>
                    <img :src="img" width="200" weight="200">
            </v-card>
        </template>
    </v-hover>

</div>

    </template>
    
    
    
    <script>
    import mixinFields from '../../mixins/Fields'
    import mixinConditionsFields from '../../mixins/conditionsFields'
    import url from '../../services/baseURL';
        export default {
            name : 'TextField',
    
            mixins : [mixinFields,mixinConditionsFields],
    
            data: function() {
                return { 
                    overlay : false,
                    img : url.root+"images/placeholder.jpg"
                }
            },
    
            computed: {
                closeChildModal() {
                    return this.$store.getters.modalShowParams({ type: 'from', val: this.page })
                }
            },    
            
            watch: {
                closeChildModal: {
                    // permet de rafraichir l'encart de la photo après selection
                    handler(val) {

                        if (val && val.show === false
                            //si c'est bien le champ d'ou provient le formulaire dans la modal :
                            &&
                            this.formThisField === true
                        ) {
                            let pic = this.$store.getters.getPicture
                            if(pic.image_url) {
                            this.img = pic.image_url
                            this.keyup(this.item,pic.image_id)
                            this.$store.dispatch('removePicture')
                            }
                        }

                    },
                    deep: true

                },
            },

            methods : {
                click() {
                    var params  = {}
                    var query   = {}
                    this.item.otherparams.selectPhoto.params.forEach((p) => {
                        if (p.type && p.type == 'query') {
                            query[p.key] = this.replaceBy({ text: p.value, page: this.page });
                        } else {
                            params[p.key] = this.replaceBy({ text: p.value, page: this.page });
                        }

                    })
                    this.formThisField = true
                    this.goTo({
                    rootPage: this.page,
                    page: this.item.otherparams.selectPhoto.page,
                    type: 'modal',
                    replace: params,
                    query: query,
                    params : {},
                    paramDialog: this.item.otherparams.selectPhoto.paramdialog,
                    fromModule: this.idmodule
                })
                },

                remove() {
                    this.keyup(this.item,null)
                    this.img = url.root+"images/placeholder.jpg"
                }
            },

            mounted() {
                this.API({type:'post', url:'bibliotheque/getimage', params:{id:this.model}}).then((resp) => {
                        this.img = resp.data
                            
                    }) 
            }
    
        }
    
    </script>