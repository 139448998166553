<template>
<div>
{{item.name}}


                        <v-menu  v-model="menu" top nudge-bottom="23" nudge-left="15" :close-on-content-click="false">
							<template v-slot:activator="{ on }">
								<div :style="swatchStyle" v-on="on" class="elevation-3"/>
							</template>
							<v-card>
								<v-card-text class="pa-0">
                                    <v-color-picker
                                    flat
                                    hide-inputs
                                    v-model="model"
                                    :rules="prop.modelRules"
                                    :name="item.value"
                                    :mode="'hexa'"
                                    :hint="prop.description"
                                    :persistent-hint="prop.persistenthint"
                                    :class="prop.class"
                                    :disabled="prop.disabled"
                                    @click:clear="model = ''"
                                    v-if="prop.show"
                                    ></v-color-picker>
								</v-card-text>
							</v-card>
						</v-menu>

</div>
</template>



<script>
import mixinFields from '../../mixins/Fields'
import mixinConditionsFields from '../../mixins/conditionsFields'
    export default {
        name : 'TextField',

        mixins : [mixinFields,mixinConditionsFields],

        data: function() {
            return { 
                menu : false
            }
        },

        computed: {
            swatchStyle() {
            const { model, menu } = this
                return {
                    backgroundColor: model,
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    borderRadius: menu ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                }
            }
        },

        watch : { 

           model(val) {
            if( typeof val === 'object') {
                this.change(this.item,val.hexa)
                this.changeStoreOnField({item:this.item,model:val.hexa,page:this.page})
            } else {
                this.change(this.item,val)
                this.changeStoreOnField({item:this.item,model:val,page:this.page})                
            }

           }

        },

        methods : {

        },

    }

</script>
