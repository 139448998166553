<template>
<div>

    <not-allowed v-if="!AllowedDisplay" :data="datas"></not-allowed>
<v-scale-transition>    
    <div v-show="show">
    <div v-if="saveFromStore">
        <v-row v-for="item in list" :key="item.nom" no-gutters>

            <v-col>             
 <v-icon  x-small v-if="$store.getters[storeTemplate+'templatePage'](keyTemplate)['mod']" @click="editField(item)">mdi-pencil</v-icon>

 <v-icon small v-if="$store.getters[storeTemplate+'templatePage'](keyTemplate)['mod'] && item.create" @click="goTo({
                rootPage: page,
                page: 'editiondroit',
                type: 'modal', 
                replace: { page:page, id: item.id, target:'field', root:'interface'},
                })">mdi-account-eye-outline</v-icon>


                <component 
                    :is="getFieldComponent(item.format)" 
                    :item="item" 
                    :keyup="onkeyup" 
                    :change="onChange"
                    type="form"
                    :page="page"
                    :modal="modal"
                    :hook="hook"
                    :storeTemplate="storeTemplate"
                    :keyTemplate="keyTemplate"
                    :valField="valFields[item.value]"
                    />            
            </v-col>


        </v-row>
        </div>
        <div v-if="!saveFromStore" :class="dependencies.class">
            <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            >
                <v-row v-for="item in list" :key="item.nom" no-gutters>

                    <v-col>             
                    <v-icon  x-small v-if="$store.getters[storeTemplate+'templatePage'](keyTemplate)['mod']" @click="editField(item)">mdi-pencil</v-icon>

                    <v-icon small v-if="$store.getters[storeTemplate+'templatePage'](keyTemplate)['mod'] && item.create" @click="goTo({
                        rootPage: page,
                        page: 'editiondroit',
                        type: 'modal', 
                        replace: { page:page, id: item.id, target:'field', root:'interface'},
                        })">mdi-account-eye-outline</v-icon>


                        <component 
                            :is="getFieldComponent(item.format)" 
                            :item="item" 
                            :keyup="onkeyup" 
                            :change="onChange"
                            type="form"
                            :page="page"
                            :modal="modal"
                            :hook="hook"
                            :storeTemplate="storeTemplate"
                            :keyTemplate="keyTemplate"
                            :valField="valFields[item.value]"
                            />            
                    </v-col>


                </v-row>
                <v-row v-if="!saveFromStore">
                    <v-col class="text-center">
                        <v-btn class="primary" @click="validate" :disabled="!valid">{{dependencies.butname}}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </div>

    </div>
</v-scale-transition>
</div>
</template>



<script>

    export default {
    props: ['idmodule','page','modal','parent','storeTemplate','keyTemplate','hook','customparams','customquerys'],

        data: function() {

            return {
                valid : true,
                show : false,
                AllowedDisplay : true,
                list : [],
                searchvalues : [],
                args : {},
                datas : [],
                dependencies : [],
                saveFromStore : true,
                valFields : {},
                url : ''
            }
        },

        methods :  {

            validate() {
                this.validateForm({form:this.$refs.form,page:this.page,fields:this.valFields,urlSend:this.url,
                        ifNotValid:()=>{
                            this.$store.dispatch('changeParamForm',{page:this.page,type:'validate',value:0})
                        },
                        callback:()=> {                      
                        this.$store.dispatch('removeForm', { uid:this.page })
                     }})

            },

            readDatas : function() {
                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.args}}).then((resp) => {

                this.datas = resp.data
                this.dependencies = resp.data.dependencies
                if(!resp.data.success) {
                this.AllowedDisplay = false; 
                return false;
                }

                // si le form ne teint pas compte du store (indépendante de la page)
                if(this.dependencies) {
                    this.saveFromStore = false
                    this.valFields = this.dependencies.datas
                    this.url = this.dependencies.url
                } else {
                    const newValFields = this.$store.getters.listForm(this.page).fields;
                    Object.keys(newValFields).forEach(key => {
                    this.$set(this.valFields, key, newValFields[key]);
                    });
                    
                }
            this.datas.fields.forEach((f)=> {
                if(this.$store.getters.listForm(this.page) && typeof this.$store.getters.listForm(this.page).fields[f.value]=='undefined') {
                    this.$store.dispatch('pushForm', { page:this.page, value:null, champ:f.value })
                }
            })
            this.list   = this.datas.fields;

                this.show = true                    

                })


            },   

             onkeyup : function(item,model) {

                //si on enregistre sur le store
                if(this.saveFromStore) {
                // on met a jour l'array qui permet l'intégration dans l'API
                 this.$store.dispatch('pushForm', { page:this.page, value:model, champ:item.value })

                this.$store.dispatch('constructTemplate/checkConditionTemplate',{page:this.page,field:item.value}); 

                // permet d'afficher ou non des éléments du template si les conditions sont bonnes
                this.$store.dispatch('constructTemplate/checkConditionColsAndRows',{page:this.page,field:item.value});
                } 
                    
                    this.$set(this.valFields, item.value, model);
                    
                
            },

            onChange : function(item,model,selectedKey) {
                var realValue = model;
                
                    if((item.format=='autocomplete' || item.format=='select')) {
                        realValue = [];
                        
                        if(item.defaultprops.multiple) {
                            for(var selected in model) {
                                if(typeof model[selected] == 'object') { realValue.push(model[selected][selectedKey]) } else { realValue.push(model[selected]); }
                            }
                        } else {
  
                        realValue = (typeof model =='object' && model!=null) ? model[selectedKey] : model;  
                          
                        }

                    } 
                
                    //si on enregistre sur le store
                    if(this.saveFromStore) {
                    // on met a jour l'array qui permet l'intégration dans l'API
                    this.$store.dispatch('pushForm', { page:this.page, value:realValue, champ:item.value })

                    // permet de changer le template si la condition est bonne  
                    this.$store.dispatch('constructTemplate/checkConditionTemplate',{page:this.page,field:item.value});

                    // permet d'afficher ou non des éléments du template si les conditions sont bonnes
                    this.$store.dispatch('constructTemplate/checkConditionColsAndRows',{page:this.page,field:item.value});
                    } 

                this.$set(this.valFields, item.value, realValue);
            },

            editField(field) {
                if (field.create) {
                    this.goTo({
                    rootPage: this.page,
                    page: "editionfield",
                    type: "modal",
                    replace: { id: field.id, variant : 0 },
                    paramDialog: { width: 1000 },
                    });
                } else {

                    this.goTo({
                    rootPage: this.page,
                    page: "editionfield",
                    type: "modal",
                    replace: { name: field.name, table : field.table },
                    paramDialog: { width: 1000 },
                    });
                }
            },

            editDroit(field){
                this.goTo({
                rootPage: this.page,
                page: 'editiondroit',
                type: 'modal', 
                replace: { page:this.page, id: field.id, target:'field', root:'interface'},
                })                
            }


        },
        mounted() {

        if(!this.modal) {
            this.args = this.$route.params;
        } else {
            this.args = this.$store.getters.modalShowParams({type:'to',val:this.page}).params           
        }
        this.args = (this.customparams) ? this.customparams : this.args;

            this.readDatas()
 
        }

    }

</script>