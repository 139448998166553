const getFormValues = {
    state: {

        listForm: []

    },

    getters: {

        allListForm: (state) => {
            return state.listForm
        },
        listForm: (state) => (id) => {
            return state.listForm.find(specific => specific.page === id)
        },

    },

    mutations: {

        pushForm: function(state, liste) {
            var ee = {};
            if (liste.key) {

                ee[liste.champ] = {};
                ee[liste.champ][liste.key] = liste.value
            } else {
                ee[liste.champ] = (liste.value == null) ? '' : liste.value;
            }
            var list = state.listForm.find(specific => specific.page === liste.page)['fields'];
            state.listForm.find(specific => specific.page === liste.page)['fields'] = {...list, ...ee };
        },

        initForm: function(state, liste) {
            var form = { page: 0, fields: {}, isLoaded: false, valid: false, validate: 0, returnActions: {}, typeform: '' }
            form = {...form, ...liste }
            state.listForm = state.listForm.filter(x => {
                return x.page != liste.page;
            })
            state.listForm.push(form)
        },

        pushFirstForm: function(state, liste) {
            var form = { page: liste.page, fields: liste.values, valid: liste.valid, validate: false }
            state.listForm = state.listForm.filter(x => {
                return x.page != liste.page;
            })
            state.listForm.push(form)
        },

        removeForm: function(state, page) {
            delete state.listForm.find(specific => specific.page === page)
        },

        changeParamForm: function(state, param) {
            state.listForm.find(specific => specific.page === param.page)[param.type] = param.value
        },

    },

    actions: {

        pushForm: function({ commit }, liste) {
            commit('pushForm', liste)
        },

        initForm: function({ commit }, liste) {
            commit('initForm', liste)
        },
        pushFirstForm: function({ commit }, liste) {
            commit('pushFirstForm', liste)
        },

        removeForm: function({ commit }, page) {
            commit('removeForm', page);
        },
        changeParamForm: function({ commit }, param) {
            commit('changeParamForm', param);
        },

    }

}
export default getFormValues;