const divers = {
    state: {

        reloadModules: [],
        reloadTables: [],
        activeTab: {},
        lastStored: {},
        stringSearch: '',
        picture: {},
        gedFile: [],
        frontDatas: {},
        ventilation: [],

    },

    getters: {

        allDiversStates: (state) => {
            return state
        },

        reloadModules: (state) => {
            return state.reloadModules
        },

        getLastStored: (state) => {
            return state.lastStored
        },

        reloadTables: (state) => {
            return state.reloadTables
        },

        filterListOptions: (state) => (page) => {
            return state.filterListOptions.find(el => el.page === page)
        },

        activeTab: (state) => {
            return state.activeTab
        },
        getStringSearch: (state) => {
            return state.stringSearch
        },
        getPicture: (state) => {
            return state.picture
        },
        getGedFile: (state) => {
            return state.gedFile
        },
        getFrontDatas: (state) => {
            return state.frontDatas
        },
        getVentilation: (state) => {
            return state.ventilation
        },
    },

    mutations: {

        changeStringSearch: function(state, string) {
            state.stringSearch = string;
        },

        setActivTab: function(state, elem) {
            var ee = {}
            ee[elem.hook] = elem.tab
            state.activeTab = {...state.activeTab, ...ee }
        },

        addReloadModule: function(state, elem) {
            if (Array.isArray(elem.modules)) {
                state.reloadModules = [...state.reloadModules, ...elem.modules]
            } else {
                state.reloadModules.push(elem.modules)
            }

        },

        removeReloadModule: function(state, elem) {
            state.reloadModules = state.reloadModules.filter(data => data != elem);
        },

        addReloadTables: function(state, elem) {
            if (Array.isArray(elem.modules)) {
                state.reloadTables = [...state.reloadTables, ...elem.modules]
            } else {
                state.reloadTables.push(elem.modules)
            }

        },

        removeReloadTables: function(state, elem) {
            state.reloadTables = state.reloadTables.filter(data => data != elem);
        },

        setLastStored: function(state, array) {
            state.lastStored[array.page] = array.fields
        },

        removeLastStored: function(state, page) {

            delete state.lastStored[page]
        },

        setPicture: function(state, pic) {

            state.picture = pic
        },
        removePicture: function(state) {

            state.picture = {}
        },
        putGedFile: function(state, file) {

            state.gedFile.push(file)
        },
        removeGedFile: function(state, file) {

            state.gedFile = state.gedFile.filter(el => el.path != file.path)
        },
        removeAllGedFile: function(state) {

            state.gedFile = []
        },
        setFrontDatas: function(state, datas) {
            /*let e = {}
            e[datas.module] = datas.datas */
            delete state.frontDatas[datas.module]
            state.frontDatas[datas.module] = datas.datas

        },

        addVentilation: function(state, datas) {
            state.ventilation.push(datas)
        },

        removeVentilation: function(state, datas) {
            state.ventilation = state.ventilation.filter(el => el.id != datas.id)
        },

    },

    actions: {

        addReloadModule: function({ commit }, module) {
            commit('addReloadModule', module)
        },
        removeReloadModule: function({ commit }, module) {
            commit('removeReloadModule', module)
        },

        addReloadTables: function({ commit }, module) {
            commit('addReloadTables', module)
        },
        removeReloadTables: function({ commit }, module) {
            commit('removeReloadTables', module)
        },

        setLastStored: function({ commit }, array) {
            commit('setLastStored', array)
        },

        removeLastStored: function({ commit }, page) {
            commit('removeLastStored', page)
        },

        setActivTab: function({ commit }, tab) {
            commit('setActivTab', tab)
        },
        changeStringSearch: function({ commit }, string) {
            commit('changeStringSearch', string)
        },
        setPicture: function({ commit }, pic) {
            commit('setPicture', pic)
        },

        removePicture: function({ commit }) {
            commit('removePicture')
        },

        putGedFile: function({ commit }, file) {
            commit('putGedFile', file)
        },

        removeGedFile: function({ commit }, file) {
            commit('removeGedFile', file)
        },

        removeAllGedFile: function({ commit }) {
            commit('removeAllGedFile')
        },
        setFrontDatas: function({ commit }, datas) {
            commit('setFrontDatas', datas)
        },
        addVentilation: function({ commit }, datas) {
            commit('addVentilation', datas)
        },
        removeVentilation: function({ commit }, datas) {
            commit('removeVentilation', datas)
        },
    }

}
export default divers;