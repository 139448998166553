<template>
    <div>

            <div v-for="(mod,i) in listModules" :key="i">
                <div v-if="!mod.module.dialog">
                <v-btn icon primary @click="close(i)" x-small><v-icon>mdi-close-thick</v-icon></v-btn>
                    <component
                    :key="key"  
                    v-bind:is="getComponent({component:mod.module.type})" 
                    :idmodule="mod.module.id" 
                    :modal="modal"
                    :page="page"
                    :form="form"
                    :customparams="mod.params"
                    :customquerys="mod.querys"
                    :storeTemplate="storeTemplate"
                    :keyTemplate="keyTemplate"
                    :hook="hook"
                    ></component>
                </div>

                <div v-if="mod.module.dialog">
                    <v-dialog
                    v-model="dialog"
                    :fullscreen="($vuetify.breakpoint.xs) ? true : false"
                    @click:outside="close(i)"
                    >
                    

                    <v-card>
                        <v-card-title class="grey lighten-2">
                        <v-btn icon @click="close(i)"><v-icon>mdi-close-thick</v-icon></v-btn>      
                        </v-card-title>

                        <v-card-text>
                            <component
                            :key="key"  
                            v-bind:is="getComponent({component:mod.module.type})" 
                            :idmodule="mod.module.id" 
                            :modal="modal"
                            :page="page"
                            :form="form"
                            :customparams="mod.params"
                            :customquerys="mod.querys"
                            :storeTemplate="storeTemplate"
                            :keyTemplate="keyTemplate"
                            :hook="hook"
                            ></component>
                        </v-card-text>


                    </v-card>
                    </v-dialog>        
                </div>            
            </div>

            <!-- Pour l'ouverture de la modal avec les actions demandés par les ventilations pour l'utilisateur-->
            <v-dialog 
            v-model="dialogVentilation" 
            persistent
            max-width="500px">
                    

                    <v-card>
                        <v-card-text>
                            <shooseUserVentilation
                            :key="reloadAction"
                            :message="messageDemand"  
                            :actions="actionsDemand"
                            @actionDone="closeVentil"
                            />
                        </v-card-text>


                    </v-card>
            </v-dialog> 

    </div>
    </template>
    
    
    
    <script>
  import OverLay from '../outils/overlay.vue'
import shooseUserVentilation from '../outils/shooseUserVentilation'
import { EventBus } from '../../services/EventBus';
        export default {
        props: ['page','modal','form','crochet','storeTemplate','keyTemplate','hook'],

        components : {
          OverLay,
          shooseUserVentilation

        },
            data: function() {
    
                return {

                    listModules : [],
                    paramsModule : {params:{},querys:{},module:{}},
                    params:{},
                    key : 0,
                    querys:{},
                    dialog : true,
                    module : {id:'',type:'',dialog:false},
                    dialogVentilation : false,
                    messageDemand : '',
                    actionsDemand : [],
                    reloadAction : 0,

                }
            },

        computed : {
            getVentilation() {
                return this.$store.getters['getVentilation']
            }
        },

        watch : {

            getVentilation(val) {
                val.forEach((ventil) => {
                    if((ventil.crochet == this.crochet && this.page == ventil.page) || (!this.crochet && ventil.crochet=='' && this.page == ventil.page)) {
                        var newModule = JSON.parse(JSON.stringify(this.paramsModule));
                        switch(ventil.type) {
                        case 'loadmodule' :
                        ventil.datas.forEach((data) => {
                            switch(data.typevar) {
                                case 'params' : 
                                newModule.params[data.key] = data.value 
                                break;
                                case 'query' : 
                                newModule.querys[data.key] = data.value 
                                break;
                            }
                            
                        })
                        newModule.module = ventil.module
                        this.listModules.push(newModule);
                        this.$store.dispatch('removeVentilation',ventil)

                        break;
                    }


                    }

                })
            }



        },

            methods :  {    
   

                initiateParams() {
                var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
                this.params = (this.modal && modal.show) ? modal.params : this.$route.params

                this.querys = (this.modal && modal.show) ? modal.query : this.$route.query
                },

                close(i) {
                    this.listModules = this.listModules.filter((el,u) => u!=i)
                },

                closeVentil(datas){
                    this.dialogVentilation = false;  
                    EventBus.$emit('send-data',datas);  
                                   
                }
                
            },
            mounted() {
                this.initiateParams()
            },
    
            created() {  
                EventBus.$on('sendDatas', (data) => {
                    if((data.crochet == this.crochet && this.page == data.page) || (!this.crochet && data.crochet=='' && this.page == data.page)) {
                        this.actionsDemand = data.actions;
                        this.messageDemand = data.message
                        this.dialogVentilation = true;
                        this.reloadAction++;
                    }
                });
            }
    
        }
    
    </script>
    