<template>
        <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
            <div>
                <Overlay/>
                <v-tabs v-model="tab" show-arrows background-color="deep-purple accent-4" icons-and-text dark grow>
                    <v-tabs-slider color="purple darken-4"></v-tabs-slider>
                    <v-tab v-for="(v,i) in tabs" :key="i">
                        <v-icon large>{{ v.icon }}</v-icon>
                        <div class="caption py-1">{{ v.name }}</div>
                    </v-tab>
                    <v-tab-item>
                        <v-card class="px-4">
                            <v-card-text>
                                <v-form ref="loginForm" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="loginEmail" :rules="loginEmailRules" label="E-mail" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="loginPassword"  :rules="[rules.required, rules.min]" type="password" name="input-10-1" label="Mot de passe" hint="At least 8 characters"></v-text-field>
                                        </v-col>

                                        <v-col class="d-flex col-12 col-sm-12 text-center">
                                            {{messageError}}
                                        </v-col>
                                        <v-col class="d-flex col-12 col-sm-6 text-center">
                                        <v-btn x-small @click="recover">mot de passe oublié</v-btn>  
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="d-flex">
                                            <v-btn :disabled="!valid" color="success" @click="validate"> Se connecter </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </div>
        </v-dialog>

</template>

<script>
import Overlay from '../outils/overlay'

 export default {

    name : "LognComponent",

    components : {
            Overlay
        },

        data: function() {
            return {
                dialog: true,
                tab: 0,
                tabs: [
                    {name:"Connexion", icon:"mdi-account"},
                ],
                valid: true,
                messageError : '',

                verify: "",
                loginPassword: "",
                loginEmail: "",
                loginEmailRules: [
                v => !!v || "e-mail requis",
                v => /.+@.+\..+/.test(v) || "requiert un e-mail valide"
                ],
                emailRules: [
                v => !!v || "Required",
                v => /.+@.+\..+/.test(v) || "requiert un e-mail valide"
                ],

                rules: {
                required: value => !!value || "mot de passe requis",
                min: v => (v && v.length >= 8) || "Minimum 8 caractères"
                }
  }
        }, 

        watch : {
            '$store.getters.isAuth' : {
                handler(val) { 
                    if(val) {
                    location.href = '/';
                    this.dialog = false 
                    }
                }                
            }
        },



        methods: {

            validate() {
                
            if (this.$refs.loginForm.validate()) {

                this.$store.dispatch('getoverlay',true);
                this.$store.dispatch('textOverlay', 'connexion en cours...' );
                this.API({type:'post', url:'/auth/login', params:{email:this.loginEmail,password:this.loginPassword}}).then((res) => {

                    if(res.data.success) {

                        localStorage.setItem('tokenMinu',res.data.token)
                        localStorage.setItem('firstConnect',true)
                        location.reload();
                    } else {
                        this.messageError = res.data.message
                        this.$store.dispatch('getoverlay',false);
                    }
                    
            })

                // submit form to server/API here...
            }
            },
            recover() {
                this.$router.push({name:'forgotp'})
            },
            reset() {
            this.$refs.form.reset();
            },
            resetValidation() {
            this.$refs.form.resetValidation();
            }
        },

        created() {
            /*
            if(localStorage.getItem('tokeMinu')!=null) {
                this.$store.dispatch('getoverlay',true);
            }
            if(this.$store.getters.isAuth) {
                    const defaultPage = this.$store.getters.currentUser.preferences.onopen
                    this.$router.push({ name: defaultPage.name, params: defaultPage.params, query: defaultPage.querys }).catch(() => {}) 
                    this.dialog = false            
            }
            */
        },


 }
</script>