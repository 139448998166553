<template>
<div>
<manageDroit :open="openManage" :editItem="editItem" @close="closeAdd"/>
  <v-btn
        v-if="type=='groupe' && datas.addgroup"
        class="ma-2"
        @click="openManage=true">ajouter un groupe</v-btn>
  <v-expansion-panels>

    <v-expansion-panel
      v-for="(typeList,i) in datas.listType"
      :key="i"
      @click="getCurrentDroit(typeList.id)"
    >
      <v-expansion-panel-header>
        <v-row no-gutters>
          <v-col cols="4">
            <v-icon left v-if="type=='perso'">
              mdi-account-outline
            </v-icon>
            
            <v-icon left v-if="type=='groupe'"           
            >
              mdi-account-multiple-outline
            </v-icon>

        <span v-for="index in typeList.niveau" :key="index">            
          <v-icon left class="ma-0">
              mdi-minus
            </v-icon></span>
            <v-icon left class="ma-0">
              mdi-arrow-right-thin
            </v-icon>
        {{typeList.libelle}}
          <span class="text-caption" v-if="users && users.length>0">(
            <span  v-for="(user,i) in users"
                  :key="i">
                  <span v-if="user.idtype == typeList.id">{{user.libelle}}</span>
            </span>
          )</span>

          </v-col>
          <v-col cols="4" v-if="type=='groupe'">
                <v-icon
                
                @click="editItem = typeList; openManage=true;"
                >mdi-pencil</v-icon>            
          </v-col>
        </v-row>
      </v-expansion-panel-header>

        <v-expansion-panel-content>

                <v-tabs v-model="tab">
                  <!-- début de la boucle des tabulations qui vont permettre l'affichage des noms de core-->
                    <v-tab v-for="(listCore,c) in datas.listElems" :key="c">{{listCore.nameCore}}</v-tab>
                    <v-tabs-items v-model="tab" class="pa-2">
                        <!-- boucle de l'intérieur des tabulations qui vont permettre l'affichage des options pour les cores-->
                        <v-tab-item v-for="(listCore,c) in datas.listElems" :key="c">
                          <div v-if="listCore.description" class="font-italic">{{listCore.description}}</div> 
                            <!-- boucle del a liste des options qui font l'objet de modification (actier; hériter etc.)-->
                            <v-col v-for="(liste,d) in listCore.list" :key="d" class="elevation-1" no-gutters>
                              <v-row align="center">
                                <v-col class="col-12 col-md-3">
                                  {{liste.libelle}}
                                  <div v-if="liste.description" class="font-italic">({{liste.description}})</div> 
                                  </v-col>
                              <v-col class="col-12 col-md-2 ">
                              <v-btn-toggle>

                                <v-btn 
                                v-for="(listChoice,q) in listCore.choices" :key="q" :value="listChoice.value"
                                @click="changeDroit(liste.id,listChoice.value,listCore.core,typeList.id)"

                                :class="classButton(listChoice,zemodel(typeList,listCore.core,liste.id),getActivState(typeList,listCore,liste.id),listCore)" 
                                >
                                  <span class="hidden-sm-and-down">{{listChoice.name}}

                                    <span v-if="listChoice.value=='legacy' && zemodel(typeList,listCore.core,liste.id)=='legacy'">({{getActivState(typeList,listCore,liste.id).text}})</span>

                                  </span>

                                  <v-icon right>
                                    {{listChoice.icon}}
                                  </v-icon>
                                </v-btn>
                              </v-btn-toggle>
                              </v-col>
                              </v-row>
                                                                                          
                            </v-col>
                          

                        </v-tab-item>

                    </v-tabs-items>
                </v-tabs>


        </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>


</div>
</template>

<script>


export default {
    name : 'droitGroupe',
    props : ['datas','type','args','users','page'],
    components : {
      manageDroit   : () => import('./manageDroit.vue'),
        },
            data: function() {
            return {
                tab : 'd',
                droit : undefined,
                currentDroit : [],
                getDatas : this.datas,
                openManage : false,
                editItem : false,
            }
        },

        computed : {

            Datas() {
              return this.datas
            }
        },
        watch : {
            Datas(val) {
              this.getDatas = val;
            }
        },

        methods : {


          closeAdd(ifreload) {
            this.openManage = false;
            this.editItem = false;
            if(ifreload) this.$emit('reload')
          },

          changeDroit(item,model,core,idType) {
            this.API({type:'post', url:'engine/droits/apply', params:{core:core,ID:item,value:model,type:this.type,typeID:idType,root:this.args.root}}).then((resp) => {
              if(resp.data.success) {
                this.$toast.success('modifié');
                  
                  this.datas.listType.find((el) => el.id === idType).droits = resp.data.array               

                this.getCurrentDroit(idType)
              } else {
                this.$toast.error(resp.data.message);
              }
            })

          },

          getCurrentDroit(id) {
            this.API({type:'post', url:'engine/droits/getLegacy', params:{type:this.type,id:id,root:this.args.root}}).then((resp) => {
              this.currentDroit = resp.data

            })


          },


          getActivState(typeList,listCore,id) {
            var retour = {text:'',value:''};
             if( this.currentDroit[listCore.core] && this.zemodel(typeList,listCore.core,id)=='legacy') {
              retour.value = this.currentDroit[listCore.core][id]
                if(this.currentDroit[listCore.core][id]==undefined) {
                  retour.text = listCore.choices.find((el) => el.default === true).name;
                } else {
                  retour.text = listCore.choices.find((el) => el.value === retour.value).name;
                }

             }
             if(this.zemodel(typeList,listCore.core,id)!='legacy') {
              retour.value = this.zemodel(typeList,listCore.core,id);
                retour.text = listCore.choices.find((el) => el.value === retour.value).name;
             }
             if(!this.currentDroit[listCore.core] && this.zemodel(typeList,listCore.core,id)=='legacy') retour.text = listCore.choices.find((el) => el.default === true).name+' par défaut'
          return retour;
          },

          zemodel(Line,core,id) {
            //si un droit est appliqué dans l'item en question, il sera activé ou désactivé.
            // par contre si il n'y a aucun droit sur cet item alors on pren les droits qui en sont hérité, et on met le bouton à "hérité". 
            var retour = 'legacy';
            if(Line.droits[this.args.root]) {

                if(Line.droits[this.args.root] && Line.droits[this.args.root][core] && Line.droits[this.args.root][core][id]!= undefined) {
                retour =  Line.droits[this.args.root][core][id];
                } 

            }

            return retour;
          },

          classButton(listChoice, model,activeState,listCore) {
            if(listChoice.value=='legacy' && model=='legacy') {
              if(activeState.value==='' || activeState.value==undefined) { return listCore.choices.find((el) => el.default === true).class } 
              else {
                return listCore.choices.find((el) => el.value === activeState.value).class
              }
              
            }

            if(model!='legacy' && activeState.value==listChoice.value) {
              return listChoice.class;
            }
          }

        },
        mounted() {
          
        }
}
</script>
