var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-md-6 col-12 pa-1"},[_c('Select',{attrs:{"item":{name:'table',value:'table',liste:_vm.listTables,
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}},"change":_vm.updateParams,"valField":_vm.ventilation.table}}),_c('TextArea',{attrs:{"keyup":_vm.updateParams,"valField":_vm.ventilation.description,"item":{name:'Description de l\'action',value:'description',
                    defaultprops:{show:true,modelRules:[],class:'pa-0 ma-0',filled:true,'autogrow':true,'row':2,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}}}),_c('ListMultiple',{attrs:{"change":_vm.updateParams,"item":{multiple:true,name:'Sur quelle action la ventilation va être lancée',value:'type',liste:[{text:'sur modification',value:'update'},{text:'sur insertion',value:'insert'},{text:'sur suppression',value:'delete'}],
                    defaultprops:{chips:true,deletablechips:true,show:true}},"valField":_vm.ventilation.type}})],1),_c('v-col',{staticClass:"col-md-6 col-12 pa-1"},[_c('Select',{attrs:{"change":_vm.updateParams,"item":{name:'Quand souhaitez-vous que la ventilation soit lancée',value:'when',liste:[{text:'Avant l\'action',value:'before'},{text:'après l\'action',value:'after'}],
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}},"valField":_vm.params.when}}),_c('Select',{attrs:{"change":_vm.updateParams,"item":{name:'sur quel champ allez vous lancer l\'action',value:'field',liste:_vm.fieldsInTable,
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}},"valField":_vm.ventilation.field}}),_c('ListMultiple',{attrs:{"change":_vm.updateParams,"item":{multiple:true,name:'sur quel type d\'enregistrement ?',value:'typeupdate',liste:[{text:'sur validation formulaire',value:'form'},{text:'sur update direct du champ',value:'field'}],
                    defaultprops:{chips:true,deletablechips:true,show:true}},"valField":_vm.ventilation.typeupdate}}),_c('TextField',{attrs:{"item":{name:'Ordre de passage',value:'order',defaultprops:{show:true}},"keyup":_vm.updateParams,"valField":_vm.ventilation.order}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.ventilation.scenario)?_c('v-col',{staticClass:"col-12 text-center"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Si ces conditions sont validés ")]),_c('v-tab',[_vm._v(" Alors cela retourne ces actions ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('Actions',{attrs:{"page":_vm.page,"modal":_vm.modal,"listTables":_vm.listTables,"fieldsInTable":_vm.fieldsInTable}})],1),_c('v-tab-item',[_c('Retours',{attrs:{"page":_vm.page,"modal":_vm.modal}})],1)],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"col-12 text-center"},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.valid},on:{"click":_vm.validate}},[_vm._v("Enregistrer")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }