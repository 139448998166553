<template>
<div v-if="field">

<v-form 
    ref="form"
    v-model="valid"
    lazy-validation
>
<v-row>

    <v-col>

<DefaultParamField @returnDisplay="changeDisplay" :params="$store.getters.listForm(this.page)['fields']['displays']" :page="page" v-if="loaded" :args="args" :field="field"/>

    </v-col>
</v-row>
    <v-row>
        <v-col class="text-center">
            <v-spacer></v-spacer>
         <v-btn class="primary" @click="validate" :disabled="!valid" >Enregistrer</v-btn>   
        </v-col>
    </v-row>
</v-form>
</div>

</template>


<script>


    export default {
        name : 'editVariant',
        props : ['page','modal'],

        components : {
            DefaultParamField : () => import('./defaultField/defaultParamField.vue'),          

        },

        data: function() {
            return { 

                urlSend : '',
                valid : false,
                displays :false,
                field : false,
                loaded : false,
                // permet de connaitre l'id qui va etre updaté (si modal on prend les infos de la modal, si page pleine on prend le router.params.id)
                args : {},

            }
        },


        methods : {

            validate(){

                if(this.field.format.form.type=='select' && this.displays.liste.length==0) {
                    alert('vous devez remplir des valeurs pour l\'affichage dans les tableaux')
                    return false;
                }
                if(this.displays.name=='' && this.field.format.form.type!='arrayfield') {
                    alert('vous devez remplir un intitulé pour l\'affichage de colonne dans les tableaux')
                    return false;
                }
                 this.$store.dispatch('getoverlay',true)
                 this.API({type:'put', url:this.urlSend, params:{id:this.args.id, values:this.$store.getters.listForm(this.page)['fields']['displays'],variant : this.args.variant}}).then((resp) => {
                        this.$store.dispatch('pushForm', { page:this.page, value: resp.data.displays ,champ:'displays'  });
                        this.$store.dispatch('getoverlay',false);
                        this.$store.dispatch('closeModal',{page:this.$store.getters.modalShowParams({type:'to',val:this.page}).from})
                    })


            },
            changeDisplay(data) {

                this.displays = data
                this.$store.dispatch('pushForm', { page:this.page, value:this.displays, champ:'displays' })
            },
            
            getFromAPI() {
                this.API({type:'post', url:this.urlSend, params:this.args}).then((resp) => {    

                        this.field = resp.data.field;
                        this.displays = resp.data.displays;

                        this.$store.dispatch('pushFirstForm', { page:this.page, values: {displays :this.displays} });
                        this.loaded = true;
                    })

            },



        },
    
        mounted() {          
     
        this.urlSend = (!this.modal) ? this.$route.path : this.$store.getters.modalShowParams({type:'to',val:this.page}).url
        this.args = (!this.modal) ? this.$route.params : this.$store.getters.modalShowParams({type:'to',val:this.page}).params

        this.getFromAPI()
        }

    }

</script>