const constructTabulation = {

    state: {

        pageTemplate: []

    },

    getters: {

        templatePage: (state) => (id) => {
            return state.pageTemplate.find(specific => specific.page === id)
        },
        tpage: (state) => {
            return state.pageTemplate
        }
    },

    mutations: {

        init_template: function(state, liste) {
            var temp = { page: liste.page, template: liste.template, mod: false, showornot: [] }
            state.pageTemplate.push(temp)
            this.commit('constructTabulation/checkConditionColsAndRows', { page: liste.page })
        },

        addCol: function(state, params) {
            var newCol = { type: "col", class: "", content: [], actionsConditions: [] }
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');

            deep.forEach(function(v) {
                template = template[v]['content']
            })
            template.push(newCol)
        },

        addRow: function(state, params) {
            var newRow = { type: "row", class: "", content: [], actionsConditions: [] }
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            switch (params.position) {
                case 'top':
                    if (params.place == '') {
                        template.unshift(newRow)
                    } else {
                        params.place.split('-').forEach(function(v) {
                            template = template[v]['content']
                        })
                        template.unshift(newRow)
                    }

                    break;
                case 'bottom':
                    if (params.place == '') {
                        template.push(newRow)
                    } else {
                        params.place.split('-').forEach(function(v) {
                            template = template[v]['content']
                        })
                        template.push(newRow)
                    }
                    break;
            }

        },

        changeElem: function(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');

            deep.forEach(function(v) {
                template = template[v]['content']
            })
            template = params.content
        },

        loadTemplate: function(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');

            if (params.place === '') {
                template.push(params.content)
            } else {
                deep.forEach(function(v) {
                    template = template[v]['content']
                })

                template.push(params.content)
            }


        },

        updateParam: function(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']

            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');
            const length = deep.length
            deep.forEach(function(v, i) {

                if (i == length - 1) {
                    if (params.key) {
                        template = template[v][params.item][params.key] = params.value
                    } else {
                        template = template[v][params.item] = params.value
                    }

                } else {

                    template = template[v]['content']
                }


            })
        },

        removeCol: function(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)['template']
            const deep = (params.place.toString().length == 1) ? [params.place] : params.place.split('-');
            var length = deep.length

            deep.forEach(function(v, i) {
                if (i == length - 1) {

                    template.splice(v, 1)

                } else {
                    template = template[v]['content']
                }


            })

        },

        applyMod(state, params) {
            var template = state.pageTemplate.find(specific => specific.page === params.page)
            template['mod'] = params.mod
        },



        checkConditionColsAndRows: function(state, elems) {
            // par defaut elems.array n'est pas créé, après la première boucle, il est créé
            var template = state.pageTemplate.find(specific => specific.page === elems.page)

            var loopArray = []
            var notShow = {}
            var variable = ''
            var showornot = template.showornot
            if (elems.array) {
                loopArray = elems.array
            } else {

                loopArray = template.template;
            }

            loopArray.forEach((t, index) => {

                if (t.type == 'col' || t.type == 'row') {

                    var parent = (elems.parent == undefined) ? index : elems.parent + "-" + index;

                    if (t.actionsConditions && t.actionsConditions.length > 0) {

                        notShow[parent] = false;
                        template.showornot = {...showornot, ...notShow }
                    }
                    if (t.actionsConditions) t.actionsConditions.forEach((cond) => {

                        variable = this._vm.replaceBy({ text: cond.varvalue, page: elems.page })

                        var egal = (!isNaN(cond.egal)) ? Number(cond.egal) : cond.egal;

                        if (variable == egal) {
                            notShow[parent] = true;
                            template.showornot = {...showornot, ...notShow }
                        }


                    })

                    if (t.content) this.commit('constructTabulation/checkConditionColsAndRows', { page: elems.page, field: elems.field, array: t.content, parent: parent })
                }

            })
        },
    },

    actions: {

        init_template: function({ commit }, liste) {
            commit('init_template', liste)
        },

        addCol: function({ commit }, params) {
            commit('addCol', params)
        },

        addRow: function({ commit }, params) {
            commit('addRow', params)
        },

        removeCol: function({ commit }, params) {
            commit('removeCol', params)
        },

        changeElem: function({ commit }, params) {
            commit('changeElem', params)
        },
        loadTemplate: function({ commit }, params) {
            commit('loadTemplate', params)
        },
        applyMod: function({ commit }, params) {
            commit('applyMod', params)
        },
        updateParam: function({ commit }, params) {
            commit('updateParam', params)
        },
        checkConditionColsAndRows: function({ commit }, liste) {
            commit('checkConditionColsAndRows', liste)
        },
    },
    namespaced: true,
}
export default constructTabulation;