<template>
    <div>

      <p class="text-h5">{{ message }}</p>

      <v-row>
        <v-col v-for="(action,i) in listActions" :key="i" class="col-12">
            <div v-if="action.type=='bouton'" class="text-center">

                <v-btn :class="[collectActions[action.key] == action.value?'primary':'secondary']" @click="selectAction(action)">{{ action.text }}</v-btn>

            </div>
            <div v-if="action.type=='liste'">
                <Select                        
                :change="(item,model,key) => {selectAction({key:action.key,value:model.value})}"
                :valField="collectActions[action.key]"
                :item="{name:action.text,liste:action.options,
                defaultprops:{show:true,modelRules:[v => !!v || 'valeur requise']}}"
                />

            </div>
            <div v-if="action.type=='textfield'">
                <TextField                        
                :keyup="(item,model) => {selectAction({key:action.key,value:model})}"
                :valField="action.value"
                :item="{name:action.text,
                defaultprops:{show:true,modelRules:[v => !!v || 'valeur requise']}}"
                />

            </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
            <v-btn @click="confirma">Continuer</v-btn>
        </v-col>
      </v-row>

    </div>
  </template>
  
  <script>

  export default {
  
          name : 'shooseUserVentilation',
  
          props:['message','actions'],
  
          components : {
            Select   : () => import('../fields/select.vue'),
            TextField: () => import('../fields/textfield.vue')
            },

          data: function() {
              return { 
                listActions : [],
                collectActions : {},
              }
          },

          watch : {
           /* actions(val) {
              this.listActions = val
              this.collectActions = {}
              this.listActions.forEach((action) => {
                if(action.type=='textfield' && action.value!='' ) {
                  this.$set(this.collectActions, action.key, action.value);
                }
              })
            } */
          },

          methods :  {    
   

            confirma() {
                if(JSON.stringify(this.collectActions) === '{}')  { 
                    this.$toast.warning('Veuillez choisir parmis les propositions')
                return;
                }
            this.$emit('actionDone',this.collectActions);
            
            },

            selectAction(action) {

                this.$set(this.collectActions, action.key, action.value);
            }
            
        },


        mounted() {

            this.listActions = this.actions;
            this.listActions.forEach((action) => {
                if(action.type=='textfield' && action.value!='' ) {
                  this.$set(this.collectActions, action.key, action.value);
                }
              })
        }
      }
  
  </script>