<template>
<div>

    <not-allowed v-if="allowed.success===false" :data="allowed"/>
    <v-container 
    v-if="init_page"
    :class="$store.getters.specificPage(page)['params']['class']"
    :fluid="$store.getters.specificPage(page)['params']['containerfluid']"
    :id="$store.getters.specificPage(page)['params']['containerid']"
    >    
   
        <v-row v-if="!pagefound">
                <v-col >

                    <v-alert
                    border="top"
                    color="red lighten-2"
                    dark
                    class="text-center"
                    >
                    la page que vous cherchez n'existe pas ({{page}})
                    </v-alert>

                </v-col>
        </v-row>

        <div v-if="$store.getters.currentUser.type==3" class="text-center grey lighten-3 text-caption ma-4">Entreprise {{returnStore('variant')}} avec contexte <b>{{returnStore('name')}}</b>
            <v-switch
                v-if="($store.getters.currentUser.type==3)"
                absolute
                v-model="ModifTemplate"
                class="pa-0 ma-0 text-center float-left"
                hide-details
            ></v-switch> 
        </div>

        <List v-if="ModifTemplate && (!hook || hook=='search')" :page="page" :modal="modal"/>

        <loadSaveTemplate 
        v-if="ModifTemplate" 
        @closeLST="closeLST" 
        :page="page" 
        type="main"
        :openModal="openSaveTemplate" 
        :templateToSave="$store.getters['constructTemplate/templatePage'](page)['template'][0]" 
        @loadTemplate="loadTemplate"/>       



        <div v-if="ModifTemplate" class="text-center pa-2">
            
            <v-divider></v-divider>

        </div>
            

                <div v-if="ModifTemplate"               
                class="col-12 elevation-1 text-center red lighten-5 rounded pa-0 ma-0 mb-1">
                <v-btn @click="savePage" class="blue pa-2 float-left" dark small><v-icon small>mdi-content-save</v-icon> template</v-btn>
                        <v-btn class="blue pa-2 float-left" dark small
                        @click="goTo({rootPage: page,page: 'editiondroit',type: 'modal',
                        // j'ai mis params:{id:item.module} juste pour permettre le rafraichissement du module... 
                        replace: { page:page, id: page, target:'page', root:'interface'},
                        })">                
                        <v-icon small>mdi-eye-outline</v-icon>
                        Droits
                        </v-btn>                
                <v-btn icon color="blue" class="ma-0 pa-0" @click="saveCurrentTemplate"><v-icon>mdi-content-save-move</v-icon></v-btn>
                <v-btn icon color="blue" class="ma-0 pa-0" @click="addRootRow('top')"><v-icon>mdi-table-row-plus-before</v-icon></v-btn>
                </div>

                <!-- intégration des ventilation de cette page si il n'y a aucun crochet-->
                <ventilation-component :page="page" :modal="modal" :hook="hook" :form="form"/>
        <div v-for="(item,index) in $store.getters['constructTemplate/templatePage'](page)['template']" :key="index">
        <Rows v-if="item.type=='row'" 
        :keyTemplate="page"
        :storeTemplate="'constructTemplate/'"
        :item="item" 
        :parent="index" 
        :index="index" 
        :page="page" 
        :modal="modal"
        :form="form"
        :typePage="typePage"
        :hook="hook"
        />
        </div>
        

                <div v-if="ModifTemplate"
                class="col-12 elevation-1 text-center red lighten-5 rounded pa-0 ma-0 mt-1">
                    <v-btn icon color="blue" @click="addRootRow('bottom')"><v-icon>mdi-table-row-plus-before</v-icon></v-btn>
                </div>




    </v-container>
</div>
</template>

<script>

    export default {
        
        props : ['page','modal','form','hook'],

        components : {

            Rows    : () => import('./Rows.vue'),
            List    : () => import('./listElem.vue'), 
            loadSaveTemplate    : () => import('./Edit/loadAndSaveTemplate.vue'),
        },

        data: function() {
            return {
            pagefound : true,
            init_page : false,
            typePage : '',
            isModified : false,
            titlePage : '',
            allowed : {message:'',success:''},
            openSaveTemplate : false,
            ModifTemplate : (this.$store.getters['constructTemplate/templatePage'](this.page)) ? this.$store.getters['constructTemplate/templatePage'](this.page)['mod'] : false,
            }
        },

        titleBrowser () {
        if(!this.hook) return this.replaceBy({text:this.$store.getters.specificPage(this.page)['displayname'],page:this.page})
        },

computed : {
    isModActif() {
        return this.$store.getters['constructTemplate/templatePage'](this.page)
    },

    isLoadedForm() {
        return this.$store.getters.listForm(this.page)
    }
},
        watch : {

            isModActif : {
                handler(val) {
                    if(val) this.isModified = val['mod']                  
                },deep:true
            },

            ModifTemplate(val) {

            this.$store.dispatch('constructTemplate/applyMod',{page:this.page, mod : val})

            },

        },

        methods : {

            returnStore(elem) {
                if(this.$store.getters['constructTemplate/actifTemplate'](this.page)[elem]) return this.$store.getters['constructTemplate/actifTemplate'](this.page)[elem];
            },

            getFromAPI() {

                this.API({type:'post', url:'page', params:{id : this.page}}).then((resp) => {


                        if(!resp.data.success) {
                            this.allowed = resp.data
                            return;
                        }
                        
                        this.typePage = resp.data.page.type
                        
                        this.$store.dispatch('constructTemplate/init_template',{page:this.page, variant : resp.data.page.displays.variant , templates : resp.data.page.displays.template, selected : 'default'})

                        this.init_page = true
                            
                        
                    })

            },


                // permet de récupérer tous les ID de modules et de les stocker dans un array pour vérifier si tous sont chargés
                getValues(obj, key) {
                    var objects = [];
                    for (var i in obj) {
                        if (!Object.prototype.hasOwnProperty.call(obj,i)) continue;
                        if (typeof obj[i] == 'object') {
                            objects = objects.concat(this.getValues(obj[i], key));
                        } else if (i == key) {
                            objects.push(obj[i]);
                        }
                    }
                    return objects;
                },

            addRootRow(pos) { 
                this.$store.dispatch('constructTemplate/addRow',{page:this.page,position:pos,place:''})
            },


            savePage() {
                this.API({type:'put', url:'savetemplate', params:{id:this.page, 
                                        template:this.$store.getters['constructTemplate/templatePage'](this.page)['templates'],
                                        variant:this.$store.getters['constructTemplate/templatePage'](this.page)['variant']
                                        }})
            },

            saveCurrentTemplate() {
                this.openSaveTemplate = true
            },
            closeLST() {
                this.openSaveTemplate = false
            },
            loadTemplate(template) {
                this.$store.dispatch('constructTemplate/loadTemplate',{page:this.page,place:'',content:template})
                this.closeLST()
            }

        },

        mounted() {
            this.getFromAPI()       

        },
        created() {
            
           if(!this.hook) this.$store.dispatch('current',this.page)

        }

    }

</script>
