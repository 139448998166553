<template>

<v-row>
    <v-col class="text-center  ma-10">
        <v-card class="lime lighten-5">
<div class="text-h5 pa-10">Veuillez selectionner un module et une table pour définir les critères</div>
            <v-icon
        color="red"

        x-large
        >mdi-antenna</v-icon>
        </v-card>
    </v-col>
</v-row>

</template>



<script>

    export default {
        name : 'NoType',
        // item étant l'array du champ avec tous ces parametres, module = numero du module, list

        data: function() {
            return { 

            }
        },

        methods : {

        },
    
        mounted() {                      

        }

    }

</script>

