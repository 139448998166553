<template>

  <v-app id="inspire">

    <NavBar v-if="$store.getters.systemLoad" />
  
    <OverLay/>

    <Loading/> 

        <modal-template v-for="(v,i) in $store.getters['show']" :key="i" :pageTemplate="v.from" :visible="v.show"></modal-template> 


         <!--$route.fullPath permet de rafraichir si un meme composant est apelé -->

        <router-view :key="keyApp()"> </router-view>

  </v-app>

</template>

<script>
import NavBar  from './components/main/navbar.vue'
import OverLay  from './components/outils/overlay.vue'

import Loading from './components/outils/loading'
  export default {
    name : 'AppComponent',

    props : ['url'],

        components: { 

          NavBar,
          Loading,
          OverLay
          },

        data: function() {
            return {
            

            }
        },

        methods : {

          keyApp() {
            if(!this.$route.query.global) return this.$route.fullPath;
          }

        },

        mounted()  {



        },

        created() {

        }


  }
</script>