var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-1",attrs:{"elevation":"2","shaped":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Paramètres du module Formulaire ")])],1),_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Champs à afficher ")]),_c('v-tab',[_vm._v(" Formulaire indépendant")]),_c('v-tabs-items',{staticClass:"pl-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',[_c('v-col',{staticClass:"ma-1"},[_c('Autocomplete',{attrs:{"item":_vm.item,"keyup":"","valField":_vm.displays.params.champs,"change":_vm.onChange}}),_c('SimpleTable',{attrs:{"Headers":[
                    { text: 'crée', value: 'create' },
                    { text: 'Nom du champ', value: 'nameoption' },
                    { text: 'clé', value: 'cle' },
                    { text: 'customisé', value : 'variant'},
                    { text: 'sur formulaire', value: 'form' },
                    { text: 'sur recherche', value: 'search' },
                  ],"datas":_vm.dataTableField,"editTable":[{ icon: 'mdi-pencil', action: _vm.editField }],"isDraggable":true,"del":false},on:{"recepVal":_vm.changeFieldOrder}})],1)],1)],1),_c('v-tab-item',[_c('SwitchC',{attrs:{"item":{name:'Activer l\'enregistrement indépendant',value:'set',defaultprops:{show:true}},"change":(item,model) => {_vm.displays.params.dependencies.set = model; _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.dependencies.set}}),(_vm.displays.params.dependencies.set)?_c('div',[_c('TextField',{attrs:{"item":{name:'nom du bouton d\'enregistrement',value:'',defaultprops:{show:true}},"keyup":(item,value) => {_vm.displays.params.dependencies.buttonname = value;  _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.dependencies.buttonname}}),_c('TextField',{attrs:{"item":{name:'class duformulaire',value:'',defaultprops:{show:true}},"keyup":(item,value) => {_vm.displays.params.dependencies.formclass = value;  _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.dependencies.formclass}}),_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},[_c('v-tab',[_vm._v("Filtre ")]),_c('v-tab',[_vm._v(" Champs")]),_c('v-tabs-items',{staticClass:"pl-4",model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},[_c('v-tab-item',[_c('Filtre',{attrs:{"page":_vm.page,"filtre":_vm.displays.params.dependencies.datas.filtre,"table":_vm.module.table},on:{"recepVal":(data)=> {_vm.displays.params.dependencies.datas.filtre = data; _vm.$emit('recepVal',_vm.displays); }}})],1),_c('v-tab-item',[_c('Fields',{attrs:{"page":_vm.page,"champs":_vm.displays.params.dependencies.datas.champs,"filter":_vm.displays.params.dependencies.datas.filtre,"table":_vm.module.table}})],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }