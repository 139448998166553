<template>
<div>

    <v-form 
        ref="form"
        v-model="valid"
        lazy-validation
    >

        <v-row no-gutters>
            <v-col class="col-md-6 col-12 pa-1">
                    
                    <Select 
                    :item="{name:'table',value:'table',liste:listTables,
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" 
                    :change="updateParams"
                    :valField="ventilation.table" />

                    <TextArea 
                    :keyup="updateParams"
                    :valField="ventilation.description"
                    :item="{name:'Description de l\'action',value:'description',
                    defaultprops:{show:true,modelRules:[],class:'pa-0 ma-0',filled:true,'autogrow':true,'row':2,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}"
                    /> 

                    <ListMultiple 
                    :change="updateParams"
                    :item="{multiple:true,name:'Sur quelle action la ventilation va être lancée',value:'type',liste:[{text:'sur modification',value:'update'},{text:'sur insertion',value:'insert'},{text:'sur suppression',value:'delete'}],
                    defaultprops:{chips:true,deletablechips:true,show:true}}" 
                    :valField="ventilation.type" />
                </v-col>
                <v-col class="col-md-6 col-12 pa-1">
                    <Select 
                    :change="updateParams"
                    :item="{name:'Quand souhaitez-vous que la ventilation soit lancée',value:'when',liste:[{text:'Avant l\'action',value:'before'},{text:'après l\'action',value:'after'}],
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" 
                    :valField="params.when" />

                    <Select 
                    :change="updateParams"
                    :item="{name:'sur quel champ allez vous lancer l\'action',value:'field',liste:fieldsInTable,
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" 
                    :valField="ventilation.field" />
        
                    <ListMultiple 
                    :change="updateParams"
                    :item="{multiple:true,name:'sur quel type d\'enregistrement ?',value:'typeupdate',liste:[{text:'sur validation formulaire',value:'form'},{text:'sur update direct du champ',value:'field'}],
                    defaultprops:{chips:true,deletablechips:true,show:true}}" 
                    :valField="ventilation.typeupdate" />

                    <TextField 
                    :item="{name:'Ordre de passage',value:'order',defaultprops:{show:true}}" 
                    :keyup="updateParams"
                    :valField="ventilation.order" />
            </v-col>
        </v-row>

        <v-row no-gutters>
                <v-col class="col-12 text-center" v-if="ventilation.scenario">

                    <v-tabs v-model="tab">
                        <v-tab>
                            Si ces conditions sont validés
                        </v-tab>       
                        <v-tab>
                            Alors cela retourne ces actions
                        </v-tab>              
                    </v-tabs>

                    <v-tabs-items v-model="tab">

                        <v-tab-item>

                            <Actions :page="page" :modal="modal" :listTables="listTables" :fieldsInTable="fieldsInTable"/>
                         
                        </v-tab-item>

                        <v-tab-item>

                            <Retours :page="page" :modal="modal" />

                        </v-tab-item>

                    </v-tabs-items>



                </v-col>
        </v-row>  

        <v-row>
                <v-col class="col-12 text-center">
                    <v-btn class="primary" @click="validate" :disabled="!valid">Enregistrer</v-btn>
                </v-col>
        </v-row>
    </v-form>
</div>
</template>


<script>
    export default {
        name : 'EditVentilation',
        props : ['page','modal'],

        components : {
            TextField   : () => import('../../fields/textfield.vue'),
            Select      : () => import('../../fields/select.vue'),
            ListMultiple   : () => import('../../fields/autocomplete.vue'),
            TextArea   : () => import('../../fields/textarea.vue'),
            Actions : () => import('./Actions.vue'),
            Retours : () => import('./Retours.vue')
        },

        data: function() {
            return { 
                urlSend : '',
                ventilation : {},
                params : {},
                valid:false,
                fieldsInTable:[],
                listTables : [],
                tab : '',
            }
        },


        methods : {

            getVentilationFromAPI() {
                this.API({type:'get', url:this.urlSend}).then((resp) => {  
                        
                        this.ventilation = resp.data.params
                        // récupération des champs de la table :
                        if(resp.data.params.table) this.getFieldsFromAPI(resp.data.params.table)

                        this.$store.dispatch('pushFirstForm', { page:this.page, values:this.ventilation});
                            if(this.params.id =='new') {
                                this.ventilation['table'] =this.params.table
                                this.getFieldsFromAPI(this.params.table)
                            }
                        })
            },

            getFieldsFromAPI(table) {
                this.getListField({filter:{table:table}}).then((resp) => {
                            this.fieldsInTable = []       

                                resp.forEach((field) => {
                                    this.fieldsInTable.push({"text":field.field,"value":field.cle})
                                })                                
                                                                      
                        })                 
                },

            validate() {
                if(this.$refs.form.validate()) {

                    this.$store.dispatch('getoverlay',true)
                this.API({type:'put', url:this.urlSend, params:this.$store.getters.listForm(this.page)['fields']}).then((resp) => {

                    var isModal = this.$store.getters.modalShowParams({type:'to',val:this.page})
                            // si on a inséré une nouvelle ligne, on va changer l'urtl pour mettre le bon id
                            if(isModal && isModal['params']['id']=='new') {
                                var newurl = isModal['propsPage']['path']
                                newurl = newurl.replace(':id', resp.data.ventilation.id)
                                this.$store.dispatch('changeOptionsModal',{modal:{type:'to',val:this.page},type:'url',value:newurl})
                                this.$store.dispatch('changeOptionsModal',{modal:{type:'to',val:this.page},type:'params',key:'id',value:resp.data.ventilation.id})
                                this.urlSend = newurl
                            }

                this.$store.dispatch('getoverlay',false)
                    if(resp.data.success) {
                        //this.$store.dispatch('closeModal',{pageTemplate:this.$store.getters.modalShowParams({type:'to',val:this.page}).from,pageModal:this.page})
                    }

                }) 
                }                
            },

            updateParams(item,model,selectedKey) {
                var realValue = (typeof model =='object') ? model[selectedKey] : model;
                this.ventilation[item.value] = realValue
                if(item.value=='table' && realValue!='') {
                    this.getFieldsFromAPI(realValue)
                }
                this.$store.dispatch('pushForm', { page:this.page, value:realValue, champ:item.value })
            },


        },
    
        mounted() {                      
            this.urlSend = (!this.modal) ? this.$route.path : this.$store.getters.modalShowParams({type:'to',val:this.page}).url
            this.params = (!this.modal) ? this.$route.params : this.$store.getters.modalShowParams({type:'to',val:this.page}).params
            this.getVentilationFromAPI()
                this.API({type:'get', url:'engine/getlisttable'}).then((resp) => {
                    var that = this
                    resp.data.forEach(function(table){
                        that.listTables.push({text:table,value:table})
                    })
                    
                })

        }

    }
</script>