<template>
    <div v-if="show">
        
        <v-dialog
        v-model="dialog"
        :width="($vuetify.breakpoint.xs) ? '100%' : '50%'">
<v-card>
    <v-card-title class="text-h5 grey lighten-2">
        "{{ mailTracked.objet }}" à {{ mailTracked.to }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close-thick</v-icon></v-btn>      
        </v-card-title>
        <v-card-text>
            <v-data-table
            v-if="trackedList.length"
        :headers="[{text:'Status',value:'status'},{text:'Date',value:'date'}]"
        :items="trackedList"
        hide-default-footer>
        </v-data-table>
        <div v-else class="text-center">
            En attente de réception
        </div>

            <div class="pa-4 ma-4 elevation-2 "  v-html="mailTracked.corp"></div>
        </v-card-text>

</v-card>
        </v-dialog>
        
        <v-btn @click="getModule" class="primary">Rafraichir la liste</v-btn>
        <v-data-table
        :headers="listTable.headers"
        :items="listTable.datas">

            <template v-slot:item="{ item}" >

                    <tr :class="[{'v-data-table__mobile-table-row':$vuetify.breakpoint.xs}]" @click="showTracked(item)">

                    <td v-for="(head,i) in listTable.headers" :key="i" 
                    :class="{'v-data-table__mobile-row':$vuetify.breakpoint.xs}">
                    
                    <!--on place ce div qui est affihé uniquement si version mobile (affiche le nom de la colonne à gauche du résultat)-->
                    <div class="v-data-table__mobile-row__header" v-html="head.text" v-if="$vuetify.breakpoint.xs"></div>

                    <div v-html="item[head.value]" 
                    :class="[{'v-data-table__mobile-row__cell':$vuetify.breakpoint.xs}]"
                    ></div>
                    </td>

                    </tr> 

            </template>
  
        </v-data-table>
    </div>
</template>
        
        
        
<script>

export default {

    name: "sendEmail",

    props: ['idmodule', 'page', 'modal', 'parent', 'hook'],

    components: {

    },

    data: function () {

        return {
            AllowedDisplay: true,
            show: false,
            datas: {},
            listTable : [],
            dialog : false,
            trackedList : [],
            mailTracked : {},
        }
    },

    watch: {

    },

    methods: {

        getModule: function () {
            this.API({ type: 'post', url: 'displaymodule', params: { id: this.idmodule, params: this.params, querys: this.querys } }).then((resp) => {

                if (!resp.data.success) {
                    this.AllowedDisplay = false;
                    this.$toast.error(resp.data.message)
                    return false;
                }
                this.datas = resp.data;
                this.listTable = resp.data.listTable
                this.show = true

            })

        },

        showTracked(item) {
            this.trackedList = []
            this.mailTracked = item;
            this.$store.dispatch('textOverlay','Obtention des informations');
            this.$store.dispatch('getoverlay', true)
            this.API({ type: 'post', url: 'displaymodule', params: { id: this.idmodule, params: this.params, querys: this.querys, tracked:item.messageID,email:item.to} }).then((resp) => {
                this.$store.dispatch('getoverlay', false)
                
                if (!resp.data.success) {
                    this.$toast.error(resp.data.message)
                    return false;
                }
                this.dialog = true;
                this.trackedList = resp.data.datas;

            })
        }


    },
    mounted() {
        var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
        let params = (this.modal && modal.show) ? modal.params : this.$route.params
        this.params = {...this.params, ...params}

        this.querys = (this.modal && modal.show) ? modal.query : this.$route.query
        this.getModule()

    },

    created() {

    }

}

</script>

        