var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.AllowedDisplay)?_c('not-allowed',{attrs:{"data":_vm.datas}}):_vm._e(),_c('v-scale-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[(_vm.saveFromStore)?_c('div',_vm._l((_vm.list),function(item){return _c('v-row',{key:item.nom,attrs:{"no-gutters":""}},[_c('v-col',[(_vm.$store.getters[_vm.storeTemplate+'templatePage'](_vm.keyTemplate)['mod'])?_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){return _vm.editField(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.$store.getters[_vm.storeTemplate+'templatePage'](_vm.keyTemplate)['mod'] && item.create)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.goTo({
                rootPage: _vm.page,
                page: 'editiondroit',
                type: 'modal', 
                replace: { page:_vm.page, id: item.id, target:'field', root:'interface'},
                })}}},[_vm._v("mdi-account-eye-outline")]):_vm._e(),_c(_vm.getFieldComponent(item.format),{tag:"component",attrs:{"item":item,"keyup":_vm.onkeyup,"change":_vm.onChange,"type":"form","page":_vm.page,"modal":_vm.modal,"hook":_vm.hook,"storeTemplate":_vm.storeTemplate,"keyTemplate":_vm.keyTemplate,"valField":_vm.valFields[item.value]}})],1)],1)}),1):_vm._e(),(!_vm.saveFromStore)?_c('div',{class:_vm.dependencies.class},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._l((_vm.list),function(item){return _c('v-row',{key:item.nom,attrs:{"no-gutters":""}},[_c('v-col',[(_vm.$store.getters[_vm.storeTemplate+'templatePage'](_vm.keyTemplate)['mod'])?_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){return _vm.editField(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.$store.getters[_vm.storeTemplate+'templatePage'](_vm.keyTemplate)['mod'] && item.create)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.goTo({
                        rootPage: _vm.page,
                        page: 'editiondroit',
                        type: 'modal', 
                        replace: { page:_vm.page, id: item.id, target:'field', root:'interface'},
                        })}}},[_vm._v("mdi-account-eye-outline")]):_vm._e(),_c(_vm.getFieldComponent(item.format),{tag:"component",attrs:{"item":item,"keyup":_vm.onkeyup,"change":_vm.onChange,"type":"form","page":_vm.page,"modal":_vm.modal,"hook":_vm.hook,"storeTemplate":_vm.storeTemplate,"keyTemplate":_vm.keyTemplate,"valField":_vm.valFields[item.value]}})],1)],1)}),(!_vm.saveFromStore)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.valid},on:{"click":_vm.validate}},[_vm._v(_vm._s(_vm.dependencies.butname))])],1)],1):_vm._e()],2)],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }