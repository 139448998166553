import axios from 'axios';

import url from './baseURL';

const token = localStorage.getItem("tokenMinu");


const api = axios.create({
    baseURL: url.api,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
    }
});

export default api;