<template>
<div>

<v-row>
    <v-col class="text-center">
        <div class="text-h1">404</div>
        <div class="text-h2">page non trouvée</div>
    </v-col>
</v-row>
<v-row>
    <v-col class="text-center">
        <v-btn class="primary rounded" to="/">Retour en page d'accueil</v-btn>
    </v-col>
    <v-col class="text-center">
        <v-btn class="primary rounded" @click="goBack">Retour en page prédédente</v-btn>
    </v-col>
</v-row>


</div>   


</template>



<script>

    export default {
        name : 'NotFound',

        data: function() {

            return {
            }
        },

        methods :  {

            goBack() {
                this.$router.go(-1)
            }
        },
        mounted() {

        },

        created() {

            if(this.$store.getters.isAuth && localStorage.getItem("firstConnect")) {
                    const defaultPage = this.$store.getters.defaultPage
                    this.goTo({ page: defaultPage.page, replace: defaultPage.args, type:'center' })  
                    this.dialog = false            
            }
            // on met ca ici car hors connexion, c'est le composant par défaut si rien n'est trouvé
            if(localStorage.getItem("tokenMinu")==null) {
              this.$router.push({name:'login'})
            }

        }

    }

</script>