var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-1",attrs:{"elevation":"2","shaped":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Paramètres de la GED")])],1),_c('div',[_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" requete ")]),_c('v-tab',[_vm._v(" Paramètres de ciblage dossier ")]),_c('v-tabs-items',{staticClass:"pl-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5 ma-3"},model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},[_c('v-tab',[_vm._v(" filtre ")]),_c('v-tab',[_vm._v(" champs ")]),_c('v-tabs-items',{staticClass:"pl-4",model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},[_c('v-tab-item',[_c('Filtre',{attrs:{"filtre":_vm.displays.filter,"table":_vm.module.table},on:{"recepVal":(data) => {_vm.displays.filter = data; _vm.$emit('recepVal',_vm.displays); }}})],1),_c('v-tab-item',[_c('Fields',{attrs:{"table":_vm.module.table,"champs":_vm.displays.params.champs,"filter":_vm.displays.filter,"page":_vm.page},on:{"recepVal":_vm.changeChamps}})],1)],1)],1)],1),_c('v-tab-item',[_c('TextField',{attrs:{"item":{name:'Dossier racine',value:'root',defaultprops:{show:true,persistenthint:true,description:'chemin ou la GED va trouver les dossiers (Il peut être vide)'}},"keyup":(item,value) => { _vm.displays.params.root = value; _vm.$emit('recepVal',_vm.displays);},"valField":_vm.displays.params.root}}),_c('Select',{attrs:{"item":{name: 'Type de navigation',value: 'navigation',liste: [{ text: 'libre', value: 'free' },{ text: 'ciblé à un dossier spécifique', value: 'folder' }],
          defaultprops:{ show: true}},"change":(item,value,select) => { _vm.displays.params.navigation = value[select]; _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.navigation}}),(_vm.displays.params.navigation=='folder')?_c('div',[_c('ArrayTable',{attrs:{"item":{otherparams:{
                arrayTable:[
                    {type:'',key:'name',name:'Nom du dossier'}]}
            ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter un dossier dès ouverture'}},"valField":_vm.displays.params.foldersonopen,"change":(item,value) => {_vm.displays.params.foldersonopen = value; _vm.$emit('recepVal',_vm.displays);}}}),_c('v-row',[_c('v-col',{staticClass:"grey lighten-3 rounded pa-3 ma-3"},[_c('div',{staticClass:"text-h6"},[_vm._v("Ciblage du dossier")]),_vm._v(" Le dossier sera automatiquement créé dès la première ouverture de la GED "),_c('Select',{attrs:{"item":{name: 'table ciblée',value: 'navigation',liste: _vm.listTables,
                      defaultprops:{ show: true}},"change":(item,value,select) => { _vm.displays.params.folderID.table = value[select]; _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.folderID.table}}),_c('TextField',{attrs:{"item":{name:'id ciblé',value:'id',defaultprops:{show:true,description:'(vous pouvez mettre la variable d\'envirronnement [[line||mon_as]]) qui provient de la requete du module'}},"keyup":(item,value) => { _vm.displays.params.folderID.id = value; _vm.$emit('recepVal',_vm.displays);},"valField":_vm.displays.params.folderID.id}}),_vm._v(" Vous pouvez rajouter un intitulé supplémentaire après l'identifiant du dossier (au moment de sa création, éléments séparés automatiquement par des espaces) "),_c('ArrayTable',{attrs:{"item":{otherparams:{
                          arrayTable:[
                              {type:'',key:'name',name:'Intitulé',description:'Vous pouvez insérer la variable d\'envirronnement [[line||mon_as]]'}]}
                      ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter un intitulé sur le nom du dossier'}},"valField":_vm.displays.params.folderName,"change":(item,value) => {_vm.displays.params.folderName = value; _vm.$emit('recepVal',_vm.displays);}}})],1),_c('v-col',{staticClass:"grey lighten-3 rounded pa-3 ma-3"},[_c('div',{staticClass:"text-h6"},[_vm._v("Dossier parent ?")]),_vm._v(" Mentionnez si le dossier ciblé appartient à un dossier ciblé parent "),_c('Select',{attrs:{"item":{name: 'table ciblée',value: 'navigation',liste: _vm.listTables,
                      defaultprops:{ show: true}},"change":(item,value,select) => { _vm.displays.params.isChildFolder.table = value[select]; _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.isChildFolder.table}}),_c('TextField',{attrs:{"item":{name:'id ciblé',value:'id',defaultprops:{show:true,description:'(vous pouvez mettre la variable d\'envirronnement [[line||mon_as]]) qui provient de la requete du module'}},"keyup":(item,value) => { _vm.displays.params.isChildFolder.id = value; _vm.$emit('recepVal',_vm.displays);},"valField":_vm.displays.params.isChildFolder.id}})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }