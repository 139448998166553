import Vue from 'vue'
import vueRouter from 'vue-router'
import store from '../store/store'


import notFound from '../components/outils/notFound.vue'
import LoginC from '../components/log/login.vue'
import Forgot from '../components/log/forgotPassword.vue'
import Recover from '../components/log/passwordRecover.vue'
import validateRegister from '../components/log/validateRegister.vue'
import activateapi from '../components/log/activationAPI.vue'
Vue.use(vueRouter)

const loginPage = { path: "/login", component: LoginC, name: 'login' }
const forgotPage = { path: "/forgotpassword", component: Forgot, name: 'forgotp' }
const activationAPI = { path: "/activationAPI/:apiName", component: activateapi, name: 'activationapi' }
const notFoundPage = { path: "*", component: notFound, name: 'notfound' }
const passRecover = { path: "/passrecover", component: Recover, name: 'recover' }
const validateRegisterPage = { path: "/validateRegister", component: validateRegister, name: 'register' }

const router = new vueRouter({
    mode: "history",
    routes: [
        notFoundPage,
        loginPage,
        forgotPage,
        passRecover,
        validateRegisterPage,
        activationAPI
    ]
})

router.beforeEach((to, from, next) => {

    //store.dispatch('purgeModal');
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next();
    } else {
        next();
    }
})

export default router;