import moment from "moment";
export default {

    name: "sendEmail",

    props: ['idmodule', 'page', 'modal', 'parent', 'hook'],

    components: {

    },

    data: function() {

        return {
            dialogTraked: false,
            trackedList: [],
            mailTracked: { id: false, email: false },
            displayTracked: { objet: '', to: '', corp: '' }
        }
    },

    watch: {
        dialogTraked(val) {
            if (val === false) {
                this.trackedList = [];
                this.mailTracked = { id: false, email: false }
                this.displayTracked = { objet: '', to: '', corp: '' }
            }
        }
    },

    methods: {

        showTracked(item) {
            this.trackedList = []
            if (!item.trackedemailid || !item.trackedemail) return;
            this.mailTracked = { id: item.trackedemailid, email: item.trackedemail, date: moment(item.trackedemaildate).format('YYYY-MM-DD') };
            this.displayTracked.to = item.trackedemail
            this.displayTracked.objet = item.trackedemailobject
            this.displayTracked.corp = item.trackedemailcorp
            this.$store.dispatch('textOverlay', 'Obtention des informations');
            this.$store.dispatch('getoverlay', true)
            this.API({ type: 'post', url: 'displaymodule', params: { id: this.idmodule, tracked: this.mailTracked } }).then((resp) => {
                this.$store.dispatch('getoverlay', false)

                if (!resp.data.success) {
                    this.$toast.error(resp.data.message)
                    return false;
                }
                this.dialogTraked = true;
                this.trackedList = resp.data.data;

            })
        }


    },

}