<template>


<v-main>    
      <!-- on met main a true afin de savoir si l'le store modal va etre remis à zero (question d'alléger l'array) -->

    <v-container>
        <v-row>
            <v-col  align="center">
                <v-progress-circular
                :indeterminate="indeterminate"
                :color="color"
                value="300"
                :size="250"
                >

                <v-list-item-avatar size="200" class="ml-4">
                    <v-img :src="logo" width="200"></v-img>
                </v-list-item-avatar>
                </v-progress-circular>
            </v-col>
            </v-row>
            <v-row>
                <v-col  align="center" v-if="isAuth===false">
                <v-icon class="red--text text-h1">mdi-close-thick</v-icon>
                <div class="text-h6">Liaisons interrompue : {{ message }}</div>

            </v-col>
            <v-col  align="center" v-if="isAuth===true">
                <v-icon class="green--text text-h1">mdi-check</v-icon>
                <div class="text-h3">Liaison effectuée avec succès !</div>
                <div>Vous allez être redirigé vers l'application...

                <v-progress-circular indeterminate value="100" :size="30" color="teal"></v-progress-circular>

                </div>

            </v-col>
            </v-row>
    </v-container>
  </v-main> 


</template>

<script>

export default {

name : "activationAPI",



    data: function() {
    return {
        isAuth : '',
        message : '',
        color : 'orange',
        indeterminate : true,
        retourAPI : {},
        logo : 'https://cdn.pixabay.com/photo/2022/01/11/21/48/link-6931554_640.png'
    }
    }, 



    methods: {
    },

    created() {

        switch(this.$route.params.apiName) {
            case 'dropbox' :
                this.logo = 'https://cdn.icon-icons.com/icons2/3041/PNG/512/dropbox_logo_icon_189235.png'
                break;
            case 'gdrive' :
                this.logo = 'https://logodownload.org/wp-content/uploads/2020/04/google-drive-logo-1-1.png'
        }

    },

    mounted() {
        this.API({type:'post', url:'/engine/getTokenAPI',params:{token:this.$route.query.code,api:this.$route.params.apiName}}).then((resp) => {

            if(resp.data.success) {
                this.color = 'green';
                this.isAuth = true;
                setTimeout(() => {
                    location.href='/'
                },4000)
            } else {
                this.color = 'red';
                this.isAuth = false;
                this.message = resp.data.message
            }
            this.indeterminate = false;       
                    })
    }


}
</script>