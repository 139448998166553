<template>
    <div>
    
        <not-allowed v-if="!AllowedDisplay" :page="page" :module="idmodule" :data="datas"></not-allowed>
    <v-scale-transition>

    <div v-show="show">
        <v-form @submit.prevent="validate"
            ref="form"
            :v-model="valid"
            lazy-validation
        >
                <v-row no-gutters justify="center" align="center">
                    <v-col v-for="item,i in list" :key="i" class="ma-1">   
                    <component 
                        :is="getFieldComponent(item.format)" 
                        :item="item" 
                        :keyup="onkeyup" 
                        type="search"
                        :change="onChange" 
                        :page="page"  
                        :valField="getFieldResult(item)"
                        /> 
                    </v-col>

                <v-col v-if="datesearch.setdebdate || datesearch.setfindate || datesearch.setmonth">
                <v-row no-gutters>
                    <v-col v-if="datesearch.setdebdate && itemdateDeb" class="col-6 mx-auto body-2 ma-2 pa-1">
                        <v-menu v-model="menudeb"                       
                        :close-on-content-click="false" transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">                                    
                                <v-col v-bind="attrs" v-on="on" class="grey lighten-4">{{ itemdateDeb.name }} : {{ monthDates.startString }}</v-col> 
                            </template>
                            <v-card>
                            
                            {{ itemdateDeb.name }} :<br>
                            <v-date-picker
                            v-model="monthDates.start"
                            locale="fr"
                            no-title
                            scrollable>
                            </v-date-picker>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="erase('start')">Effacer</v-btn>
                            </v-card>
                        </v-menu>
                    </v-col>

                    <v-col v-if="datesearch.setfindate" class="col-6 mx-auto body-2 ma-2 pa-1">
                        <v-menu v-model="menufin"                         
                        :close-on-content-click="false" transition="scale-transition" 
                        min-width="auto">
                            <template v-slot:activator="{ on, attrs }">                                    
                                <v-col v-bind="attrs" v-on="on" class="grey lighten-4">{{ itemdateFin.name }} : {{ monthDates.endString }}</v-col> 
                            </template>
                        <v-card>
                            {{ itemdateFin.name }}  :<br>
                            <v-date-picker
                            v-model="monthDates.end"
                            locale="fr"
                            no-title
                            scrollable>
                            </v-date-picker>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="erase('end')">Effacer</v-btn>
                        </v-card>
                        </v-menu>
                    </v-col>
                    <v-row no-gutters>
                        <v-col v-if="datesearch.setmonth">

                            <v-row no-gutters style="overflow:auto" justify="center" align="center">

                                <v-hover v-slot="{ hover }">
                                    <v-col :class="['text-center col-2',hover ? 'blue lighten-3': '']" style="cursor: pointer;" @click="changeDate('prev')"><v-icon>mdi-menu-left</v-icon></v-col>
                                </v-hover>
                                <v-hover v-slot="{ hover }" v-for="(defdate,i) in arrayOfMonths" :key="i">
                                    <v-col @click="changeMonthPeriod(defdate)" style="cursor: pointer;" class="text-center text-caption col-2">
                                        <v-tooltip bottom >
                                            <template v-slot:activator="{ on, attrs }">
                                               <div :class="['rounded pa-1 ma-1',hover ? 'blue lighten-3': defdate.isselected ? 'blue':'grey lighten-3']" v-bind="attrs" v-on="on">{{ defdate.name }}</div> 
                                            </template>
                                            {{ defdate.title }}
                                        </v-tooltip>
                                    </v-col>
                                </v-hover>
                                <v-hover v-slot="{ hover }">
                                    <v-col :class="['text-center col-2',hover ? 'blue lighten-3': '']" style="cursor: pointer;" @click="changeDate('next')"><v-icon>mdi-menu-right</v-icon></v-col>
                                </v-hover>

                        
                            </v-row>
                        </v-col>
                    </v-row>
                </v-row>
                </v-col>
                    <v-col>
                        <v-btn icon @click="launchSearch"><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
    
    
                </v-row>
                
        </v-form>
    </div>
    
    </v-scale-transition>
    </div>
    </template>
    
    
    
    <script>
    
        export default {
        props: ['idmodule','page','modal','parent'],
    
            data: function() {
    
                return {
                    AllowedDisplay : true,
                    valid : true,
                    list : [],
                    menudeb : false,
                    menufin :false,
                    datesearch:{},
                    monthDates : { end : '', start :'' , endString : '', startString : '', startmonth:1},
                    itemdateDeb:{},
                    itemdateFin:{},
                    arrayOfMonths : [],
                    refDate : false,
                    results : {},
                    show : false,
                    //récupère juste les datas du back, si erreur, ca part dans not-allowed
                    datas : []
                }
            },
    
            components : {
                datePicker   : () => import('../fields/datePicker.vue'),
        },

        watch : {

            'monthDates.start'(val) {
                let dat = this.moment(val)
                this.monthDates.start = dat.format('YYYY-MM-DD')
                this.monthDates.startString = dat.format('DD/MM/YYYY')
                this.results[this.itemdateDeb.value] = this.monthDates.start
                this.menudeb = false;
            },
            
            'monthDates.end'(val) {
                let dat = this.moment(val)
                this.monthDates.end = dat.format('YYYY-MM-DD')
                this.monthDates.endString = dat.format('DD/MM/YYYY')
                this.results[this.itemdateFin.value] = this.monthDates.end
                this.menufin = false;
            }, 

        },

            methods :  {    

                getFieldResult(item) {
                    if((item.format=='autocomplete' || item.format=='select')) {
                        
                        if(item.defaultprops.multiple) {
                            return (!Array.isArray(this.results[this.idmodule+'_'+item.value])) ? [this.results[this.idmodule+'_'+item.value]] : this.results[this.idmodule+'_'+item.value];
                        } else {
                            return this.results[this.idmodule+'_'+item.value];                     
                        }

                    } else {
                        return this.results[this.idmodule+'_'+item.value];
                    }
                    
                },
    
                callAxios : function() {
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,querys:this.results}}).then((resp) => {

                            if(!resp.data.success) {
                            this.AllowedDisplay = false; 
                            this.datas = resp.data
                            return false;
                            }
                            this.list = resp.data.fields;
                            this.datesearch = resp.data.datesearch
                            
                            this.itemdateDeb = {name:this.datesearch.namedeb,value:this.idmodule+'_'+this.datesearch.valuedeb}
                            this.itemdateFin = {name:this.datesearch.namefin,value:this.idmodule+'_'+this.datesearch.valuefin}
                            this.constructDatesSearch();
                            this.constructMonthSearch()
                            this.show = true 
                    })
    
                },
                 onkeyup : function(item,model,enter) {
    
                    if(item.is =="search") {
                        this.results[item.value] = model
                        if(!model) this.results[item.value] = '';
                    } else {
                        this.results[this.idmodule+'_'+item.value] = model
                        if(!model) this.results[this.idmodule+'_'+item.value] = '';
                    }

                     
                     if(enter) {
    
                        this.launchSearch()

                    }
                 },

                onChange : function(item,model,selectedKey) {
                    var realValue = model;
                    if((item.format=='autocomplete' || item.format=='select')) {
                        realValue = [];
                        
                        if(item.defaultprops.multiple) {
                            model = model.filter(el => el!= undefined);
                            for(var selected in model) {
                                
                                if(typeof model[selected] == 'object') { realValue.push(model[selected][selectedKey]) } else { realValue.push(model[selected]); }
                            }
                        } else {
  
                        realValue = (typeof model =='object' && model!=null) ? model[selectedKey] : model;  
                          
                        }

                    } 
                    this.onkeyup(item,realValue)                         
                },

                launchSearch() {
                    if(this.modal) {
                            this.$store.dispatch('changeOptionsModal',{modal:{type:'to',val:this.page},type:'query',value:this.results});
                            this.constructMonthSearch()
                        } else {
                            var query = {...this.$route.query, ...this.results}
                            this.$router.replace({query:query}).catch(() => { this.$toast.warning('filtre déjà appliqué') });

                        }
                },


                constructDatesSearch() {

                    if(this.results[this.itemdateDeb.value]) {
                        let start = this.moment(this.results[this.itemdateDeb.value])
                        this.monthDates.startString = start.format('DD/MM/YYYY');
                        this.monthDates.start = start.format('YYYY-MM-DD');
                    } else {
                        this.monthDates.startString = 'N.D.';
                    }

                    if(this.results[this.itemdateFin.value]) {
                    let end = this.moment(this.results[this.itemdateFin.value])
                    this.monthDates.endString = end.format('DD/MM/YYYY');          
                    this.monthDates.end = end.format('YYYY-MM-DD'); 
                    } else {
                        this.monthDates.endString = 'N.D.' 
                    }

                },

                constructMonthSearch() {

                    let start = this.moment();
                    if(this.refDate) start = this.refDate
                    if(!this.refDate && this.results[this.itemdateDeb.value]) {
                        start = this.moment(this.results[this.itemdateDeb.value]);
                    }
                    var monthToSelect = start.subtract(3,'month')

                    this.refDate = monthToSelect
                        for (let i = 0; i < 4; i++) {
                            monthToSelect = monthToSelect.add(1,'month')
                            let isselected = (
                                        (this.results[this.itemdateDeb.value] || this.results[this.itemdateFin.value]) 
                                        &&
                                        ( 
                                            (
                                            monthToSelect.startOf('month').add(15,'day').isBetween(this.results[this.itemdateDeb.value],this.results[this.itemdateFin.value]) 
                                            ||
                                            monthToSelect.format('YY-MM') == this.moment(this.results[this.itemdateDeb.value]).format('YY-MM')
                                            )
                                        )
                                    ) ? true : false;
                            this.arrayOfMonths.push({
                                deb : monthToSelect.startOf('month').format('YYYY-MM-DD'),
                                fin : monthToSelect.endOf('month').format('YYYY-MM-DD'),
                                isselected : isselected,
                                name : monthToSelect.format('MM'),
                                title : 'du '+monthToSelect.startOf('month').format('DD/MM/YYYY')+' au '+monthToSelect.endOf('month').format('DD/MM/YYYY')})
                        }                       
                    
                },

                erase(is) {
                    switch(is) {
                        case 'end' :
                        this.results[this.itemdateFin.value] = ''
                        this.monthDates.endString = 'N.D.'
                        this.menufin = false;
                        break;
                        case 'start':
                        this.results[this.itemdateDeb.value] = ''
                        this.monthDates.startString = 'N.D.';
                        this.menudeb = false;
                        break;
                    }
                },

                changeDate(mouv) {
                    this.arrayOfMonths = []
                    //if(mouv=='next') this.refDate.subtract(1,'month')
                    if(mouv=='prev') this.refDate.subtract(2,'month')
                    this.constructMonthSearch()
                },

                changeMonthPeriod(dates) {
                    this.results[this.itemdateDeb.value] = dates.deb
                    this.results[this.itemdateFin.value] = dates.fin
                    this.launchSearch()
                }
    
            },
            mounted() {
                var mod = this.$store.getters.modalShowParams({ type: 'to', val: this.page })

                                if(this.modal && mod.show) {
                                    this.results = {...this.results,...mod.query}
                                } else {
                                    this.results = {...this.results,...this.$route.query}
                                }

                            // on check si une valeur est un nombre, alors on le converti en nombre en js
                            for( var pa in this.results) {
                                if(this.results[pa]!='' && !isNaN(this.results[pa])) this.results[pa] = parseInt(this.results[pa]);
                                if(this.results[pa]=='false') this.results[pa] = false;
                                if(this.results[pa]=='true') this.results[pa] = true;

                                if(Array.isArray(this.results[pa])) this.results[pa] = this.results[pa].map((id) => { return Number(id) });
                            }

                this.callAxios()    
            },
    
            created() {  

            }
    
        }
    
    </script>
    