<template>

<v-card>

    <not-allowed v-if="!AllowedDisplay"></not-allowed>

        <v-scale-transition>    
            <div v-show="show">
                            
                <v-tabs
                v-model="tab"
                :right="paramTabs.right"
                :background-color="paramTabs.bgcolor"
                :centered="paramTabs.centered"
                :dark="paramTabs.dark"
                :icons-and-text="paramTabs.iconText"
                :vertical="paramTabs.vertical"
                >

                    <v-tabs-slider></v-tabs-slider>

                    <v-tab v-for="(tabulation,i) in listTabs" :key="i" 
                    :href="'#tab-'+i"
                    :class="tabulation.params.class"
                    :dark="tabulation.params.dark"
                    @click="setActiveTab('tab-'+i)"
                    >
                    {{tabulation.name}}
                    <v-icon v-if="tabulation.params.icon">{{tabulation.params.icon}}</v-icon>
                    </v-tab>                   
            

                <v-tabs-items v-model="tab">
                <v-tab-item
                    
                    v-for="(tabulation,i) in listTabs"
                    :key="i"
                    :id="'tab-'+i"
                    :value="'tab-'+i"
                    :dark="tabulation.params.dark"
                >
                    <v-card flat>
                    <v-card-text>

                <v-switch
                v-if="($store.getters.currentUser.type==3)"
                v-model="isModified[tabulation.id]"
                class="pa-0 ma-0 text-center"
                hide-details
                ></v-switch>
                <v-btn icon v-if="isModified[tabulation.id]" @click="savePage(tabulation.id)" class="blue" dark><v-icon>mdi-content-save</v-icon></v-btn>

                        <List v-if="$store.getters['constructTabulation/templatePage'](tabulation.id)['mod']" :page="page"/>

                        <v-row no-gutters v-if="$store.getters['constructTabulation/templatePage'](tabulation.id)['mod']">
                            <v-col                 
                            class="col-12 elevation-1 text-center red lighten-5 rounded mt-1 mb-1">
                                <v-btn icon color="blue" @click="addRootRow(tabulation.id,'top')"><v-icon>mdi-table-row-plus-before</v-icon></v-btn>
                            </v-col>
                        </v-row>

                                <v-row no-gutters  v-for="(item,index) in $store.getters['constructTabulation/templatePage'](tabulation.id)['template']" :key="index">
                                <Rows v-if="item.type=='row'" 
                                :keyTemplate="tabulation.id"
                                :storeTemplate="'constructTabulation/'"
                                :item="item" 
                                :parent="index" 
                                :index="index" 
                                :page="page" 
                                :form="form"
                                :modal="modal"
                                typePage="page"
                                :hook="hook"
                                />
                                </v-row>
                    

                        <v-row no-gutters v-if="$store.getters['constructTabulation/templatePage'](tabulation.id)['mod']">
                            <v-col 
                            class="col-12 elevation-1 text-center red lighten-5 rounded mt-1 mb-1">
                                <v-btn icon color="blue" @click="addRootRow(tabulation.id,'bottom')"><v-icon>mdi-table-row-plus-before</v-icon></v-btn>
                            </v-col>
                        </v-row>


                    </v-card-text>
                    </v-card>
                </v-tab-item>
                </v-tabs-items>
            </v-tabs >
            </div>
        </v-scale-transition>
   
    </v-card>

</template>



<script>

    export default {

    name :'TabsModule',
    props: ['idmodule','page','modal','form','hook'],

    components : {

            Rows    : () => import('../../main/templates/page/Rows.vue'),
            List    : () => import('../../main/templates/page/listElem.vue'), 
        },

        data: function() {

            return {

                show : false,
                AllowedDisplay : true,
                tab : '',
                listTabs : [],
                paramTabs : [],
                isModified: [],
                args : {}

            }
        },

        computed : {
            activeTab() {
                return this.$store.getters.activeTab['tabs_'+this.idmodule];
            }
        },

        watch : {

            isModified(val) {

                val.forEach((t,i)=> {
                    this.$store.dispatch('constructTabulation/applyMod',{page:i, mod : t})
                })
                
            },

            activeTab(val) {

                this.tab = val
            }

        },

        methods :  {

            readDatas : function() {
                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.args}}).then((resp) => {
                        
                        if(!resp.data.success) {
                        this.AllowedDisplay = false;
                        this.$emit('isloaded',{mod:this.idmodule,'resp':true}); 
                        return false;
                        }

                    resp.data.tabs.forEach((elem) => {

                    this.$store.dispatch('constructTabulation/init_template',{page:elem.id,template:elem.content})
                    })
                    this.listTabs = resp.data.tabs
                    this.paramTabs = resp.data.params

                    // on informe au composant parent que le module est chargé
                    this.$emit('isloaded',{mod:this.idmodule,'resp':true});

                    this.show = true                
                })


            },

            savePage(tab) {
                this.API({type:'put', url:'tabulation/savecontent', params:{id :tab, content : this.$store.getters['constructTabulation/templatePage'](tab)['template']}}).then(() => {                           
                        
                    })
            },

            addRootRow(tab,pos) { 
                this.$store.dispatch('constructTabulation/addRow',{page:tab,position:pos,place:''})
            },

            setActiveTab(tab) {
                this.$store.dispatch("setActivTab",{hook:'tabs_'+this.idmodule,tab:tab})
            }

        },
        mounted() {

            if(!this.modal) {
                this.urlSend = this.$route.path;
                this.args = this.$route.params;
            } else {
                this.urlSend = this.$store.getters.modalShowParams({type:'to',val:this.page}).url
                this.args = this.$store.getters.modalShowParams({type:'to',val:this.page}).params           
            }
            this.readDatas()
            this.tab = this.$store.getters.activeTab['tabs_'+this.idmodule]
        }

    }

</script>